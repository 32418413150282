<template>
  <nav class="top-nav" :class="{ open: isOpen }">
    <div class="navigation-header">
      <router-link :to="{ name: 'who-we-are' }" class="logo-container">
        <img src="~@/assets/img/logo-dpi.png" v-if="isDpiScreen" alt="E-aligner DPI"/>
        <img src="~@/assets/img/logo.png" v-else alt="E-aligner"/>
      </router-link>
      <div class="open-btn-container" @click="isOpen = !isOpen">
        <SvgCross v-if="isOpen" />
        <SvgMenu v-else />
      </div>
    </div>
    <div class="navigation-content">
      <div class="router-links-container">
        <router-link :to="{ name: 'who-we-are' }" @click.native="closeNav">Qui sommes-nous</router-link>
        <router-link :to="{ name: 'why-choose-us' }" @click.native="closeNav">Pourquoi nous choisir ?</router-link>
        <router-link :to="{ name: 'team' }" @click.native="closeNav">L'équipe</router-link>
        <router-link :to="{ name: 'partner' }" @click.native="closeNav">Partenaire</router-link>
        <router-link :to="{ name: 'contact' }" @click.native="closeNav">Contact</router-link>
        <router-link :to="{ name: 'faq' }" @click.native="closeNav">FAQ</router-link>
        <router-link :to="{ name: 'smile-photo-aligner' }" @click.native="closeNav">Simulation</router-link>
      </div>
      <div class="cta-container">
        <a href="tel:0241469378"><app-button look="secondary">Tel : 02 41 46 93 78</app-button></a>
        <a target="_blank" :href="appUrl"><app-button>Connexion praticien</app-button></a>
      </div>
      <div class="social-network-container">
        <a class="svg-link" target="_blank" href="https://fr-fr.facebook.com/ealigner/" title="Facebook" ><SvgFacebook /></a>
        <a class="svg-link" target="_blank" href="https://fr.linkedin.com/company/e-aligner-la-solution-invisible-d'alignement-dentaire" title="Linkedin"><SvgLinkedin /></a>
      </div>
    </div>
  </nav>
</template>

<script>
import SvgFacebook from '@/assets/img/icons/facebook.svg';
import SvgLinkedin from '@/assets/img/icons/linkedin.svg';
import SvgMenu from '@/assets/img/icons/menu.svg';
import SvgCross from '@/assets/img/icons/cross.svg';

export default {
  name: 'top-nav',
  components: {
    SvgFacebook,
    SvgLinkedin,
    SvgMenu,
    SvgCross,
  },
  props: {
    isDpiScreen: Boolean,
  },
  data() {
    return {
      appUrl: `${process.env.VUE_APP_FRONT_URL}/traitements`,
      isOpen: false,
    };
  },
  methods: {
    closeNav() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="sass">
.top-nav
  position: fixed
  top: 0
  width: 100%
  display: flex
  padding: 21px 32px
  background: white
  justify-content: space-between
  align-items: center
  gap: 30px
  z-index: 100
  svg path
    fill: var(--main-color, $main-color)
  .navigation-header
    display: flex
    align-items: center
    justify-content: space-between
    .logo-container img
      height: 35px
      width: fit-content
    .open-btn-container
      display: none
  .navigation-content
    display: flex
    justify-content: space-between
    align-items: center
    flex-grow: 2
    gap: 10px
    .router-links-container
      display: flex
      justify-content: space-around
      align-items: center
      flex-grow: 2
      a
        @include text-infos
        color: $text-color
        &.router-link-active
          @include text-infos-bold
          color: $text-dark-neutral-color
    .cta-container
      display: flex
      justify-content: center
      align-items: center
      gap: 16px
    .social-network-container
      display: flex
      justify-content: flex-end
      align-items: center
      gap: 9px
      .svg-link
        padding: 18px 3px
        display: block

@media (max-width: 1200px)
  .top-nav
    flex-direction: column
    padding: 3vw 7vw
    align-items: center
    justify-content: center
    .navigation-header
      width: 100%
      .open-btn-container
        display: block
        width: 23px
    .navigation-content
      position: absolute
      top: 0
      left: 0
      transform: translateY(-100%)
      width: 100vw
      height: calc(100vh - var(--header-height))
      overflow-y: auto
      background: $white
      flex-direction: column
      align-items: flex-start
      padding-bottom: 40px
      transition: transform 0.3s ease-out
      > div
        width: 100%
      .router-links-container
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start
        padding-left: 10vw
        gap: 30px
        flex-grow: 2
      .cta-container
        flex-direction: column
        align-items: center
        gap: 24px
      .social-network-container
        justify-content: center

    &.open
      .navigation-header
        .logo-container
          opacity: 0
      .navigation-content
        transform: translateY(var(--header-height))

@media (max-height: 800px)
  .top-nav
    .navigation-content
      .router-links-container
        gap: 1rem
      .cta-container
        gap: 0.5rem

</style>
