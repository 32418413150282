<template>
  <div class="screen team">
    <!-- Block Histoire -->
    <section class="history-block">
      <div class="block-content">
        <div class="img-container">
          <img src="~@/assets/img/illustrations/production-team.jpg" />
        </div>
        <div class="text-container">
          <h2>
            <app-subtitle>Histoire</app-subtitle>
            L'histoire d'E-Aligner
          </h2>
          <p>
            Développé par le groupe français <strong class="super-strong">INNOVA PHARM</strong>, spécialiste de l’Orthodontie fonctionnelle depuis plus de 20 ans, E-Aligner est LA solution d’alignement dentaire sur mesure et 100% Française offrant une <strong>alternative transparente aux appareils orthodontiques classiques.w</strong> E-Aligner a été créée avec le souhait de conjuguer <strong>technicité, efficacité, réactivité et proximité.</strong>
          </p>
          <p>
            <strong>Cliniquement prouvée, la solution E-Aligner a été conçue pour apporter aux praticiens</strong> et à leurs patients un traitement sur-mesure, que ce soit pour un alignement simple ou plus complexe. Tout en apportant à chaque patient un résultat irréprochable et <strong>une approche thérapeutique efficace, pédagogique et personnalisée.</strong>
          </p>
        </div>
        <div class="img-container">
          <img src="~@/assets/img/illustrations/hand-with-gloves-holding-gutter.jpg" />
        </div>
        <div class="text-container">
          <p>
            <strong>Confortable, transparente et amovible</strong>, la gouttière E-Aligner est l’appareil orthodontique personnalisé par excellence, qui déplace les dents progressivement et avec <strong>simplicité, efficacité et douceur.</strong>
          </p>
          <p>Notre équipe, ci-dessous, est composée de professionnels expérimentés, sympathiques et accessibles, toujours prêts à répondre à vos questions du lundi au vendredi de 8h30 à 12h30 et de 14h à 17h.</p>
        </div>
      </div>
    </section>

    <!-- Block Equipe -->
    <section class="team-block">
      <h2>
        <app-subtitle>L'équipe E-Aligner</app-subtitle>
        Une équipe experte dans le traitement <br/> d'alignement dentaire
      </h2>
      <div class="team-content">
        <div v-for="member in team" :key="member.name" class="member-card">
          <div class="img-container">
            <img :src="require(`@/assets/img/illustrations/team-members/${member.picture}`)" :class="member.class" />
          </div>
          <div class="member-card-content">
            <h3>{{ member.name }}</h3>
            <p>{{ member.job }}</p>
          </div>
        </div>
      </div>
    </section>
    <MapBlock />
  </div>
</template>

<script>
import MapBlock from '@/components/MapBlock.vue';

export default {
  name: 'Team',
  components: {
    MapBlock,
  },
  data() {
    return {
      team: [
        {
          name: 'Patrice', job: 'Fondateur', picture: 'Patrice.jpeg', class: 'patrice',
        },
        {
          name: 'Michel', job: 'Directeur Financier', picture: 'Michel.jpeg', class: 'michel',
        },
        {
          name: 'Sadia', job: 'Opératrice de production', picture: 'Sadia.jpeg', class: 'sadia',
        },
        {
          name: 'Maëlenn', job: 'Opératrice de production', picture: 'Maelenn.jpeg', zoom: '250%', class: 'maelenn',
        },
        {
          name: 'Pauline', job: 'Responsable production', picture: 'Pauline.jpeg', class: 'pauline',
        },
        {
          name: 'Bunny', job: 'Opérateur clinique', picture: 'Bunny.jpeg', class: 'bunny',
        },
        {
          name: 'Vincent', job: 'Responsable clinique', picture: 'Vincent.jpeg', class: 'vincent',
        },
        {
          name: 'Claire', job: 'Chargée de développement commercial', picture: 'Claire.jpeg', class: 'claire',
        },
      ],
    };
  },
};
</script>

<style lang="sass">
.team.screen
  width: 100%
  .history-block
    padding: 100px
    background: white
    .block-content
      max-width: 1200px
      margin: 0 auto
      display: grid
      grid-template-areas: "illustration-1 text-1 text-1" "text-2 text-2 illustration-2"
      row-gap: 120px
      column-gap: 3vw
      grid-template-columns: auto 5vw auto
      align-items: center
      .img-container
        position: relative
        z-index: 0
        img
          height: 100%
          width: 100%
          object-fit: cover
          border-radius: 40px
          z-index: 0
        &::after
          width: 130px
          display: block
          content: url("data:image/svg+xml,%3Csvg viewBox='0 0 152 198' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.142578 126.749L38.267 0.194824L128.804 96.4888L0.142578 126.749Z' fill='%236BBB32'/%3E%3Cpath d='M66.1804 173.943L129.655 111.676L151.843 197.78L66.1804 173.943Z' fill='%236BBB32'/%3E%3C/svg%3E")
          position: absolute
          z-index: -1
        &:first-child
          grid-area: illustration-1
          height: 390px
          img
            border-top-right-radius: 0
            border-bottom-left-radius: 0
          &::after
            top: 50%
            left: 0
            transform: translate(-40%, -10%)
        &:nth-child(3)
          grid-area: illustration-2
          height: 350px
          img
            border-top-left-radius: 0
            border-bottom-right-radius: 0
          &::after
            bottom: 0
            right: 0
            transform: translate(75%, 20%)
      .text-container
        margin-left: 50px
        .app-subtitle
          text-align: left
        .super-strong
          color: $main-color
        &:nth-child(2)
          grid-area: text-1
        &:last-child
          grid-area: text-2

  .team-block
    padding: 100px
    background: $bg-light-color
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    .team-content
      display: flex
      flex-wrap: wrap
      gap: 40px
      margin: 70px auto 0 auto
      max-width: 1200px
      align-items: stretch
      justify-content: center
      .member-card
        width: 261px
        border-radius: 24px 0 24px 0
        overflow: hidden
        background: $white
        text-align: left
        .img-container
          width: 100%
          height: 164px
          border-bottom-right-radius: 10px
          overflow: hidden
          position: relative
          img
            object-fit: cover
            height: 100%
            width: 100%
            &.patrice
              width: 150%
              height: 150%
              object-position: center
              transform: translate(-25%, -15%)
            &.michel
              width: 150%
              height: 150%
              object-position: center
              transform: translate(-30%, 0)
            &.sadia
              width: 150%
              height: 150%
              object-position: center
              transform: translate(-3%, -20%)
            &.maelenn
              width: 220%
              height: 220%
              object-position: right
              transform: translate(-50%, -25%)
            &.pauline
              width: 160%
              height: 160%
              object-position: center
              transform: translate(-25%, -29%)
            &.mathilde
              width: 150%
              height: 150%
              object-position: center
              transform: translate(-22%, -20%)
            &.bunny
              width: 150%
              height: 150%
              object-position: center
              transform: translate(-12%, -15%)
            &.vincent
              width: 150%
              height: 150%
              object-position: center
              transform: translate(-27%, -13%)
            &.claire
              width: 140%
              height: 140%
              object-position: center
              transform: translate(-27%, -12%)

        .member-card-content
          padding: 20px
          h3, p
            margin: 0
          h3
            margin-bottom: 5px
            color: $main-color

@media (max-width: 768px)
  .screen.team
    .history-block
      padding: 26px 7vw 100px 7vw
      .block-content
        display: flex
        flex-direction: column
        gap: 50px
        .img-container
          img
            object-position: 20% 20%
          &:after
            width: 95px
            height: 95px
          &:first-child
            height: 255px
            &::after
              transform: translate(-45%, -10%)
          &:nth-child(3)
            height: 235px
            img
              border-radius: 40px 0 40px
            &::after
              transform: translate(60%, 0%)
        .text-container
          margin: 0

    .team-block
      padding: 70px 4vw
      .team-content
        gap: 16px 12px
        justify-content: center
        .member-card
          width: 160px
          .img-container
            height: 170px
            img
              &.michel
                object-position: right
                transform: translate(-10%, 0)
              &.sadia
                object-position: left
                transform: translate(-25%, -20%)
              &.maelenn
                transform: translate(-35%, -25%)
              &.vincent
                transform: translate(-31% -13%)
              &.claire
                object-position: right
                transform: translate(-5%, -15%)
              &.fabien
                transform: translate(-30%, -7%)
          .member-card-content
            padding: 16px
</style>
