<template>
  <div class="screen-partner screen">
    <div class="content">
      <div class="image-container">
        <div class="image">
          <img class="foreground" src="~@/assets/img/illustrations/blue-tooth.png" alt=""/>
          <img class="illustration" src="~@/assets/img/illustrations/smile.png" alt=""/>
          <img class="background" src="~@/assets/img/illustrations/dpi-background-motif.png" alt=""/>
        </div>
      </div>
      <div class="text-container">
        <div class="text">
          <h1>Notre partenaire DPI</h1>
          <h4>Depuis 2019, DPI France & DPI Belgique : équipes professionnelles et passionnées, vous proposent une solution d'alignement dentaire 100% française en partenariat avec E-Aligner.</h4>
          <p>Notre activité repose sur un accompagnement personnalisé ainsi qu'une écoute de vos besoins et de celle de vos patients. Cet espace permet de proposer à vos patients une évaluation et une visualisation complète en 3D de leur traitement et de leur dentition corrigée</p>
          <a href="./qui-sommes-nous#comment-ca-marche"><app-button>En savoir plus</app-button></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Partner',
  data() {
    return {};
  },

};
</script>

<style lang="sass">
  .screen-partner
    padding-top: 2vw
    .content
      margin-left: 6%
      margin-right: 6%

    .image
      position: relative
      padding: 0 25px

      .illustration
        position: relative
        border-radius: 10% 0 10% 0
        z-index: 2
      .foreground
        position: absolute
        top: 0
        right: 0
        transform: translate(15%, -50%)
        width: 20%
        z-index: 3
      .background
        position: absolute
        width: 50%
        bottom: 0
        right: 0
        transform: translate(0, 35%)
        z-index: 1

    .text
      padding: 0 25px

      h1
        margin-top: 0

      .app-button
        margin-top: 50px

    .background
      width: 300px

  @media (min-width: 769px)
    .screen-partner
      .content
        display: grid
        grid-auto-columns: 50% 50%
        margin-bottom: 220px

      .image-container
        margin-top: 25px
        grid-column: 1/2

        .image
          position: relative
          top: 50%
          transform: translateY(-50%)

      .text-container
        grid-column: 2/2

        .text
          position: relative
          top: 50%
          transform: translateY(-50%)

  @media (max-width: 768px)
     .screen-partner
      .content
        margin: 5vw 0 0 0
      .image
        margin-left: auto
        margin-right: auto
        .foreground
          transform: translate(0, -30%)
      .text
        margin: 20% auto 100px auto
</style>
