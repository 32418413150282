<template>
  <div class="app-carousel" v-if="slides.length">
    <div class="carousel-container">
      <div class="carousel-content">
        <div v-for="(slide, index) in slidesSrc" :key="slide.slideId" class="slide-item" :class="{ active: currentSlideIndex === index, next: nextSlideIndex === index, previous: previousSlideIndex === index}">
          <template v-if="slide.linkUrl">
            <a :href="slide.linkUrl" target="_blank">
              <img :src="slide.image" />
            </a>
          </template>
          <img v-else :src="slide.image" />
        </div>
        <!-- Permet que carousel-content a toujours une taille -->
        <div class="slide-item item-placeholder">
          <img :src="slidesSrc[0].image" />
        </div>
      </div>
      <div class="carousel-control-right" @click.prevent="goToSlideManual(currentSlideIndex + 1)"><SvgArrow /></div>
      <div class="carousel-control-left"  @click.prevent="goToSlideManual(currentSlideIndex - 1)"><SvgArrow /></div>
      <div class="carousel-controls-dot" v-if="slides.length > 1">
        <div class="control-dot" v-for="(slide, index) in slides" :key="index" :class="{ active: currentSlideIndex === index }" @click="goToSlideManual(index)" />
      </div>
    </div>
  </div>
</template>

<script>
import SvgArrow from '@/assets/img/icons/arrowhead-left.svg';

export default {
  components: {
    SvgArrow,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    autoplay: { // Rotation automatique des slides
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSlideIndex: 0,
      interval: null,
      displayDuration: 0,
    };
  },
  watch: {
    slides() {
      this.currentSlideIndex = 0;
    },
  },
  mounted() {
    if (this.autoplay) {
      this.displayDuration = +process.env.VUE_APP_SLIDES_AUTOPLAY_DURATION * 1000;
      this.interval = setInterval(this.goToNextSlide, this.displayDuration || 2000);
    }
  },
  computed: {
    // Génère la source des images selon leur buffer
    slidesSrc() {
      return this.slides.map((img) => ({
        ...img,
        image: `data:image/jpeg;base64,${img.image}`,
      }));
    },
    previousSlideIndex() {
      return (this.currentSlideIndex + this.slides.length - 1) % this.slides.length;
    },
    nextSlideIndex() {
      return (this.currentSlideIndex + 1) % this.slides.length;
    },
  },
  methods: {
    goToNextSlide() {
      this.currentSlideIndex = (this.currentSlideIndex + 1) % this.slides.length;
    },
    // Modifier la slide de façon manuelle (reset la rotation automatique si besoin)
    goToSlideManual(slideIndex) {
      if (this.autoplay) {
        clearInterval(this.interval);
        this.interval = setInterval(this.goToNextSlide, this.displayDuration || 2000);
      }
      this.currentSlideIndex = (slideIndex + this.slides.length) % this.slides.length;
    },
  },
};
</script>

<style lang="sass">
.app-carousel
  max-width: 1200px
  position: relative
  .carousel-container
    position: relative
    width: 100%
    margin: auto
    overflow-x: hidden
    .carousel-content
      position: relative
      width: 80%
      margin: auto
      aspect-ratio: 16/9
      .slide-item
        position: absolute
        width: 100%
        flex-shrink: 0
        transition: all 0.5s ease-in-out
        z-index: 0
        opacity: 0
        top: 0
        left: 0
        img
          aspect-ratio: 16/9
          width: 100%
        &.item-placeholder
          position: relative
        &.active, &.previous, &.next
          opacity: 1
        &.active
          transform: scale(1)
          z-index: 2
        &.previous
          transform: translateX(-20%) scale(0.8)
          z-index: 1
        &.next
          transform: translateX(20%) scale(0.8)
          z-index: 1

    .carousel-control-right, .carousel-control-left
      position: absolute
      top: 50%
      transform: translateY(-50%)
      padding: 1rem
      cursor: pointer
      z-index: 10
      svg
        width: 3rem
        filter: drop-shadow(0px 4px 8px rgb(0, 0, 0))
        opacity: 0.5
        path
          fill: white
      &:hover
        svg
          opacity: 1
    .carousel-control-right
      right: 10%
    .carousel-control-left
      left: 10%
      transform: rotate(180deg) translateY(50%)

    .carousel-controls-dot
      position: absolute
      bottom: 2rem
      width: 100%
      display: flex
      justify-content: center
      gap: 1rem
      z-index: 10
      .control-dot
        width: 1em
        height: 1em
        border-radius: 100px
        background: $color-gray-20
        transition: all 0.3s ease-in-out
        cursor: pointer
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.5))
        &.active
          background: $white
          width: 48px

  @media (max-width: 768px)
    .carousel-container
      .carousel-content
        width: 100%
        .slide-item
          &.active
            z-index: 5
          &.next
            transform: translateX(100%) scale(1)
          &.previous
            transform: translateX(-100%) scale(1)
      .carousel-control-right, .carousel-control-left
        padding: 0
        height: 100%
        display: flex
        align-items: center
        justify-content: center
      .carousel-control-right
        right: 0
      .carousel-control-left
        left: 0

</style>
