<template>
  <div class="screen why-choose-us">
    <section class="become-partner">
      <div class="become-partner-container">
        <div class="become-partner-content">
          <div>
            <h1>
              <em>Devenir partenaire</em> et rejoignez E-Aligner dans son expertise qualitative
            </h1>
            <p>
              E-Aligner a été développé par le Groupe Français Innova Pharm, spécialiste de l’Orthodontie
              fonctionnelle depuis 20 ans. E-Aligner offre une alternative transparente aux appareils
              orthodontiques classiques.
            </p>
            <div class="become-partner-btn">
              <a href="#our-advantages">
                <app-button>Devenir partenaire d’E-Aligner</app-button>
              </a>
              <a href="#formulas">
                <app-button look="secondary">Voir les formules</app-button>
              </a>
            </div>
          </div>
        </div>
        <div class="become-partner-img">
          <img src="@/assets/img/illustrations/dentist-showing-teeth.png" alt="Dentiste montrant les dents du patient" loading="lazy" defer="async" />
          <div class="become-partner-label">
            <img src="@/assets/img/illustrations/made-in-france.png" alt="100% Français" loading="lazy" defer="async" />
          </div>
        </div>
      </div>
    </section>

    <section class="our-advantages" id="our-advantages">
      <div class="our-advantages-container">
        <div class="our-advantages-img">
          <img src="@/assets/img/illustrations/teeth-gutter.png" alt="Dentiste montrant les dents du patient" loading="lazy" defer="async" />
        </div>
        <div class="our-advantages-content">
          <div>
            <p>Nos avantages</p>
            <h2>Pourquoi nous choisir en tant que partenaire ?</h2>
            <div class="our-advantages-collapses">
              <div class="our-advantages-collapse">
                <app-collapse title="Des produits sur mesure et qualitatifs" v-model="activeCollapse" name="product-collapse" variant="list">
                  <template v-slot:image>
                    <div class="collapse-img">
                      <SvgTeethContract />
                    </div>
                  </template>
                  <template v-slot:default>
                    E-Aligner s’inscrit dans une dynamique d’excellence dans la fabrication de ses produits et de ses traitements par gouttières transparentes.
                    Nos gouttières d’alignement sont des dispositifs médicaux sur mesure, amovibles, confortables et créées sur la base d’un matériau thermoplastique,
                    résistant et biocompatible.
                  </template>
                </app-collapse>
              </div>
              <div class="our-advantages-collapse">
                <app-collapse title="Une visualisation précise à votre patient" v-model="activeCollapse" name="visiualization-collapse" variant="list">
                  <template v-slot:image>
                    <div class="collapse-img">
                      <SvgJaw />
                    </div>
                  </template>
                  <template v-slot:default>
                    Créez dès aujourd’hui votre compte professionnel E-ALIGNER, et proposez une visualisation précise à votre patient de son
                    nouveau sourire avant même de commencer un traitement par gouttières. Une visualisation 3D unique de votre plan de traitement !
                  </template>
                </app-collapse>
              </div>
              <div class="our-advantages-collapse">
                <app-collapse title="Un réel accompagnement" v-model="activeCollapse" name="escort-collapse" variant="list">
                  <template v-slot:image>
                    <div class="collapse-img">
                      <SvgTchat />
                    </div>
                  </template>
                  <template v-slot:default>
                    Le + d’accompagnement E-ALIGNER déploie tous les moyens nécessaires à l’obtention du meilleur traitement et résultat pour vos patients.
                  </template>
                </app-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <video-block
      videoSrc="https://www.youtube-nocookie.com/embed/Njju_CwQ8eQ"
      title="Votre plan de traitement en 3D personnalisé."
      subtitle="prévisualisation"
    >
      <a href="#formulas">
        <app-button>Découvrir nos formules</app-button>
      </a>
    </video-block>
    <steps-block id="etapes"
      title="Un processus simplifié pour un alignement dentaire performant"
      :steps="steps"
    />
    <section class="formulas" id="formulas">
      <div class="formulas-header">
        <p>Informations complémentaires</p>
        <h2>Les formules d’E-Aligner</h2>
      </div>
      <div class="formulas-card">
        <div class="formulas-card-content">
          <div class="table-container">
            <div class="table-title">
              <div class="collapse-img">
                <SvgTreatment/>
              </div>
              <div class="text">
                <h3>Les traitements</h3>
                <h4>(aligner tri-couches)</h4>
              </div>
            </div>
            <div class="table">
              <div class="table-content">
                <div class="grid-y">
                  <div class="cell">TRAITEMENT - 1 ARCADE</div>
                  <div class="cell"><Gutters :number="5"/><p>gouttières</p></div>
                  <div class="cell"><Gutters :number="10"/><p>gouttières</p></div>
                  <div class="cell"><Gutters :number="15"/><p>gouttières</p></div>
                </div>
                <div/>
                <div class="grid-y">
                  <div class="cell">TRAITEMENT - 2 ARCADES</div>
                  <div class="cell"><Gutters :number="5"/><SvgPlus/><Gutters :number="5" :reverse="true"/><p>gouttières</p></div>
                  <div class="cell"><Gutters :number="10"/><SvgPlus/><Gutters :number="10" :reverse="true"/><p>gouttières</p></div>
                  <div class="cell"><Gutters :number="15"/><SvgPlus/><Gutters :number="15" :reverse="true"/><p>gouttières</p></div>
                </div>
              </div>
              <div class="cell shared"><Gutters/><p>gouttière intermédiaire</p></div>
            </div>
          </div>
        </div>
      </div>
      <div class="formulas-card">
        <div class="formulas-card-content">
          <div class="table-container">
            <div class="table-title">
              <div class="collapse-img">
                <SvgFormula/>
              </div>
              <div class="text">
                <h3>La formule "Tranquilité"</h3>
                <h4>(aligner tri-couches)</h4>
              </div>
            </div>
            <div class="table">
              <div class="table-content">
                <div class="grid-y">
                  <div class="cell">TRAITEMENT - 1 ARCADE</div>
                  <div class="cell">Plan de traitement</div>
                  <div class="cell">Révisions et finitions</div>
                  <div class="cell">Aligner tri-couches</div>
                  <div class="cell">1 Contention filaire (ou gouttière)</div>
                  <div class="cell">Casse ou pertes (3 gouttières)</div>
                </div>
                <div/>
                <div class="grid-y">
                  <div class="cell">TRAITEMENT - 2 ARCADES</div>
                  <div class="cell">Plan de traitement</div>
                  <div class="cell">Révisions et finitions</div>
                  <div class="cell">Aligner tri-couches</div>
                  <div class="cell">1 Contention filaire (ou gouttière) / arcade</div>
                  <div class="cell">Casse ou pertes (3 gouttières) / arcade</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="formulas-card">
        <div class="formulas-card-content">
          <h3>Les contentions</h3>
          <div>
            <p>
              <strong>Protégeons ensemble le sourire de vos patients.</strong>
            </p>
            <p>
              Pour conserver le résultat du traitement et le sourire de votre patient une fois le traitement orthodontique achevé, la contention est nécessaire pour maintenir le résultat acquis sous peine de récidive de l'anomalie initiale ou d'apparition d'une nouvelle pathologie.
              <br><br>
              E-Aligner propose deux types de contention, <strong>la contention par gouttière</strong> qui doit être portée quotidiennement 8h/24h minimum (préconisé en nocturne) et <strong>la contention filaire</strong> adaptée à la morphologie du patient et qui ne nécessite pas plus d’un contrôle par an pour le patient. Cela permet de conserver le plus longtemps possible le nouveau sourire de votre patient."
            </p>
          </div>
        </div>
        <div class="formulas-card-img">
          <img src="@/assets/img/illustrations/ealigner-box.png" alt="Les contentions" loading="lazy" defer="async" />
        </div>
      </div>
    </section>
    <section class="contact-us">
      <div>
        <div class="contact-us-content">
          <p>Interessé par E-aligner</p>
          <h2>Vous avez des questions ? Contactez-nous !</h2>
          <p>Notre équipe est là pour répondre à l’ensemble de vos questions, et vous accompagner au mieux dans l’utilisation de la solution E-Aligner. </p>
          <div>
            <router-link :to="{ name: 'contact' }">
              <app-button look="primary" background="dark">Contactez-nous</app-button>
            </router-link>
          </div>
        </div>
        <div class="contact-us-img">
          <img src="@/assets/img/illustrations/ealigner-panorama-img.png" alt="Vous avez des questions ? Contactez-nous !" loading="lazy" defer="async" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VideoBlock from '@/components/VideoBlock.vue';
import StepsBlock from '@/components/StepsBlock.vue';
import Gutters from '@/components/Gutters.vue';

import SvgJaw from '@/assets/img/icons/jaw.svg';
import SvgTchat from '@/assets/img/icons/tchat.svg';
import SvgTeethContract from '@/assets/img/icons/teeth-contract.svg';
import SvgTeeth from '@/assets/img/icons/teeth.svg';
import SvgPatientFile from '@/assets/img/icons/patient-file.svg';
import SvgTreatment from '@/assets/img/icons/treatment.svg';
import SvgFormula from '@/assets/img/icons/formula.svg';
import SvgPlus from '@/assets/img/icons/plus.svg';

export default {
  name: 'WhoWeAre',
  components: {
    VideoBlock,
    StepsBlock,
    Gutters,
    SvgTchat,
    SvgTeethContract,
    SvgJaw,
    SvgTreatment,
    SvgFormula,
    SvgPlus,
  },
  data() {
    return {
      activeCollapse: null,
      steps: [
        {
          icon: SvgJaw,
          title: 'Une évaluation rapide et accompagnée',
          subtitle: 'Pré-étude gratuite',
          description: 'Notre équipe vous transmet gratuitement un devis sous 72h maximum (pré-étude). Sans engagement, il permet de connaître la faisabilité du traitement, la durée de celui-ci et son coût. Vous échangez par la suite avec votre patient pour donner suite ou non a ce devis et ainsi lancer un plan de traitement avec 3D personnalisé.',
        }, {
          icon: SvgTeeth,
          title: 'Une planification de traitement complète',
          subtitle: 'Réalisation d’un plan de traitement',
          description: 'Sur votre compte professionnel E-Aligner, enregistrez le dossier de votre patient en complétant le formulaire de prescription (photographies intra et extra orales) et en nous transmettant ses empreintes dentaires. Grâce à la technologie 3D E-aligner, nous élaborons ensemble avec notre orthodontiste un plan de traitement personnalisé, vous permettant de visualiser et suivre avec votre patient son traitement et l’évolution de son sourire étape par étape.',
        }, {
          icon: SvgPatientFile,
          title: 'Un suivi de traitement simple pour d’excellents résultats',
          subtitle: 'Validation du protocole et des gouttières',
          description: 'Une fois le plan de traitement validé sur votre compte E-Aligner, les gouttières d’alignement sur mesure de votre patient seront fabriquées dans notre laboratoire en Maine et Loire (49) et expédiées directement à votre cabinet. L’équipe E-Aligner reste à vos cotés et vous accompagne tout au long du traitement de votre patient.',
        },
      ],
    };
  },
};
</script>

<style lang="sass">
.why-choose-us
  overflow: hidden
  .become-partner
    display: flex
    justify-content: center
    align-items: center
    padding: 100px 120px

    &-container
      max-width: 1200px
      display: grid
      grid-template-columns: 1fr 1fr
      grid-template-rows: 1fr
      column-gap: 6rem

    &-content
      display: flex
      align-items: center
      position: relative
      z-index: 1
      padding-left: 2rem

      > div
        display: flex
        flex-direction: column

      h1
        margin: 0 0 2rem 0
        &::after
          content: ""
          position: absolute
          z-index: -1
          top: -5%
          left: -10px
          border-style: solid
          border-width: 7rem 12rem 7rem 0
          border-color: transparent $color-primary-100 transparent transparent
          opacity: 0.1
          transform: rotate(180deg)

      em
        font-style: normal
        color: $color-primary-100

      p
        margin: 0 0 2.5rem 0
        line-height: 24px

    &-img
      display: flex
      position: relative
      z-index: 1

      > img
        object-fit: contain
        border-radius: 2.5rem 0 2.5rem 0

      &::after,
      &::before
        content: ""
        position: absolute
        z-index: -1
        border-style: solid

      &::before
        top: 125px
        right: -75px
        border-width: 6rem 9rem 6rem 0
        border-color: transparent $color-primary-100 transparent transparent
        transform: rotate(115deg)

      &::after
        top: 126px
        right: -75px
        border-width: 6rem 9rem 6rem 0
        border-color: transparent $white transparent transparent
        transform: scale(0.95) rotate(115deg)

    &-btn
      display: flex
      column-gap: 1rem

    &-label
      position: absolute
      top: -10%
      right: -5%
      width: 100px
      height: 100px

      img
        object-fit: contain

  .our-advantages
    display: flex
    justify-content: center
    align-items: center
    padding: 100px 0

    &-container
      max-width: 1200px
      width: 90%
      display: grid
      grid-template-columns: 0.75fr 1fr
      grid-template-rows: 1fr
      column-gap: 6rem

    &-collapse:not(:last-child)
        margin-bottom: 1rem

    &-img
      display: flex

      > img
        object-fit: contain
        border-radius: 2.5rem 0 2.5rem 0

    &-content
      p
        text-transform: uppercase
        margin: 0 0 0.5rem 0
        @include subtitle

      h2
        margin: 0 0 1.5rem 0

  .collapse-img
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    background-color: $main-color
    border-radius: 50%

    > svg
      width: 34px !important
      height: 34px !important

  .formulas
    max-width: 1200px
    margin: 0 auto
    padding: 7rem 0
    display: flex
    flex-direction: column
    align-items: center

    &-header
      display: flex
      flex-direction: column
      justify-content: center
      text-align: center
      margin-bottom: 2rem

      p
        margin: 0
        text-transform: uppercase
        @include subtitle

      h2
        margin: 0

    &-card
      width: 100%
      background-color: $bg-light-color
      border-radius: 2.5rem
      padding: 3rem 8rem

      &:not(:last-child)
        margin-bottom: 2rem

      &:last-child
        display: flex
        flex-direction: row
        justify-content: space-between
        column-gap: 2.5rem
        padding: 5rem 8rem

        .formulas-card-content
          max-width: 650px
          width: 100%

        .formulas-card-img
          margin: auto
          height: 100%
          max-width: 270px
          border

          img
            border-radius: 2.5rem 0 2.5rem 0

    &-card-content
      h3
        @include h2

      .table-container
        margin-bottom: 2rem

        .table-title
          display: flex
          margin-bottom: 2rem

          .text
            max-width: calc(100% - 80px)
            display: flex
            flex-wrap: wrap

          .collapse-img
            margin: auto 0
            margin-right: 20px
            width: 60px
            height: 60px

            > svg
              width: 100% !important
              height: 100% !important

          h3
            margin: auto 0
            margin-right: 8px

          h4
            margin: auto 0
            font-size: 21px

        .table
          background-color: $white
          border-radius: 10px
          padding: 40px

          &-content
            display: grid
            grid-template-columns: 1fr minmax(auto, 90px) 1fr
            text-align: center

          .cell
            display: flex
            flex-direction: row
            justify-content: center

            &:first-child
              padding: 20px
              margin-bottom: 30px
              color: $color-gray-80
              font-weight: 600
              background-color: $color-primary-40
              border-radius: 4px

            &:not(:first-child)
              padding: 16px
              font-weight: 700
              border-bottom: 1px solid $color-primary-50

            &.shared
              width: fit-content
              margin: 0 auto
              padding: 20px 50px

            img, svg
              margin: auto 0
              margin-right: 8px

            img
              width: 60px
              height: 50px

            svg
              width: 18px
              height: 18px

            >p
              margin-bottom: 4px
              margin-top: auto

    &-card-img
      display: flex
      width: 100%
      max-width: 180px

      > img
        object-fit: contain
        width: 100%

  .contact-us
    background-color: $color-primary-100
    display: flex
    justify-content: center
    padding: 5rem 0

    > div
      display: flex
      flex-direction: row
      justify-content: space-between
      max-width: 1200px
      column-gap: 2rem
      width: 100%

    &-content
      display: flex
      flex-direction: column
      justify-content: center
      max-width: 400px
      color: $white

      p:first-child
        @include subtitle
        color: $white
        margin: 0
        text-transform: uppercase

      h2
        color: $white
        margin: 0 0 2rem 0

    &-img
      display: flex
      width: 100%
      max-width: 500px

      > img
        object-fit: contain

  @media (max-width: 768px)
    .our-advantages
      &-container
        display: flex
        flex-direction: column
        row-gap: 2rem

      h2
        font-size: 22px

    .become-partner
      padding: 0

      &-btn
        flex-direction: column
        row-gap: 0.5rem

      &-label
        bottom: -40px
        left: 0
        top: unset
        right: unset

      &-container
        display: flex
        flex-direction: column-reverse

      &-content
        margin-top: 3rem
        padding: 0 1.5rem

        p
          margin-bottom: 4rem

        h1
          font-size: 24px

          &::after
            border-width: 5rem 10rem 5rem 0
            top: -8vw

      &-img
        img
          border-radius: 0

        &::before
          border-width: 0

        &::after
          border-width: 0

    .formulas
      padding: 6rem 1.5rem

      &-header
        margin-bottom: 2.5rem
        h2
          font-size: 22px

      &-card
        flex-direction: column
        flex-wrap: wrap
        padding: 2rem
        row-gap: 2rem

        h3
          font-size: 22px

        &:last-child
          padding: 2rem

        .table-container
          .table-title
            display: flex

          .table
            padding: 16px

            &-content
              display: flex
              flex-wrap: wrap
              gap: 1rem

              >div
                width: 100%

            .cell.shared
              margin-top: 4rem
              width: 100%

        &-img
          margin: 0 auto

    .contact-us
      padding: 3rem 1.5rem 5rem 1.5rem

      > div
        flex-direction: column-reverse

      &-content
        max-width: 100%

        h2
          font-size: 22px

      &-img
        max-width: 100%
        margin-bottom: 4rem

    .collapse-img
      height: 40px
      width: 40px

      > svg
        width: 26px !important
        height: 26px !important
</style>
