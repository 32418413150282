<template>
  <section class="video-block">
    <h2>
      <app-subtitle>{{ subtitle }}</app-subtitle>
      {{ title }}
    </h2>
    <figure class="video-container">
      <iframe
        width="832"
        height="464"
        :src="`${videoSrc}?controls=0`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        ref="video"
      ></iframe>
      <!--<app-button class="play-btn" @click="playVideo"  :class="{ hidden: showNativeControls }">
        <SvgPlay />
      </app-button>-->
    </figure>
    <slot></slot>
  </section>
</template>

<script>
// import SvgPlay from '@/assets/img/icons/play.svg';

export default {
  name: 'VideoBlock',
  components: {
    // SvgPlay,
  },
  props: {
    videoSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showNativeControls: false,
    };
  },
  methods: {
    // Lance la vidéo et réaffiche les controls natifs du navigateur
    playVideo() {
      this.$refs.video.play();
      this.showNativeControls = true;
    },
    // Cache les controls natifs du navigateur
    hideNativeControls() {
      this.showNativeControls = false;
    },
  },
};
</script>

<style lang="sass">
.video-block
  background: $bg-dark-color
  padding: 70px 0
  text-align: center
  h2
    margin-top: 0
    color: $white
  figure.video-container
    position: relative
    width: 100%
    padding: 0
    margin: 52px auto
    video
      width: 90vw
      max-width: 800px
      margin: auto
      object-fit: cover
    .play-btn
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 84px
      height: 84px
      border-radius: 50%
      background: $main-color
      border: 3px solid $main-color
      &.primary.bg-light:hover
        background: #9FDA75
      &.hidden
        display: none
      svg
        width: 80%
        margin: auto
        transform: translate(10%)

@media (max-width: 768px)
  .video-block
    h2
      font-size: 22px
      padding: 0 24px
    figure.video-container
      iframe
        width: 100vw
</style>
