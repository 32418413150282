<template>
  <div class="gutters" :class="{ reverse }">
    <img src="@/assets/img/illustrations/gutters.png" alt=""/>
    <p>{{ this.number }}</p>
  </div>
</template>

<script>
export default {
  name: 'gutters',
  props: {
    number: {
      default: null,
      type: Number,
    },
    reverse: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="sass">
.gutters
  position: relative
  margin: auto 0
  padding-top: auto
  height: 50px

  p
    position: absolute
    margin: 0
    padding-right: 8px
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-weight: 700
    font-size: 27px
    color: $color-gray-90

  &:not(.reverse)
    p
      padding-top: 10px

  &.reverse
    p
      padding-bottom: 10px

    img
      transform: scaleY(-1)
</style>
