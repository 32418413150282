<!--
****************************************************************************************************
***                                         Select                                               ***
****************************************************************************************************

Composant select, il gère :
  - la recherche / filtre des résultats
  - le déplacement aux fleches haute et basse avec validation du résultat avec entrée
  - le clic en dehors qui ferme le select (directive clickOutside)
  - select multiple ou non

*************
*** PROPS ***
*************

  - options :
    Les valeurs d'options sont un tableau d'objet contenent un label et un clé
    sous le format { name: 'aaa', label: 'bbb' }

  - multiple :
    select multiple ou non

  Exemple :
  <app-select
    :options="[{ name: 'lorem', label: 'Lorem Ipsum'}, { name: 'lorem', label: 'Lorem Ipsum'}]"
    :multiple="true"
    v-model="selectValue"/>

-->
<template>
  <div :class="['app-select', { disabled, open: isOpen, 'is-invalid': isInvalid }]" role="listbox" tabindex="0">
    <div class="box" @click="handleBoxClick">
      <input type="text" class="filter" :class="{ visible : filter.length > 0}"
        ref="select"
        @keydown.down="keyDown()"
        @keydown.up="keyUp()"
        @keydown.enter="onChange(preselectedOption)"
        v-model="filter"
        :disabled="disabled"
      />

      <span v-if="filter.length === 0">
        <slot v-if="multiple">
          <span class="result" v-for="result in value" :key="`result${result}`">
            {{ options.find(o => o.name === result)['label'] }}
            <span class="remove" @click.stop="clearValue(result)">✕</span>
          </span>
        </slot>
        <slot v-else>
          <span class="result" v-if="options.find(o => o.name === value)">
            {{ options.find(o => o.name === value)['label'] }}
          </span>
        </slot>
      </span>

      <span v-if="!value || value.length === 0" class="empty">Choisir</span>
    </div>

    <transition name="select">
      <ul
        v-if="isOpen"
        ref="optionsBox"
        class="options"
        v-click-outside="closeSelect"
      >
          <li v-for="option in filteredOptions"
            role="option"
            :key="option.name"
            :ref="`option${option.name}`"
            :class="{ selected: option.name === value || (value && value.includes(option.name)), preselected: option.name === preselectedOption }"
            @click="onChange(option.name)"
            @mouseenter="preselectedOption = option.name">{{ option.label }}</li>
          <li v-if="filteredOptions.length === 0" @click="isOpen = false">Aucun résultat</li>
      </ul>
    </transition>

    <select
      :value="value"
      v-bind="$attrs"
      @invalid.prevent="handleInvalid"
    >
      <option v-for="option in options" :key="option.name" :value="option.name" >
        {{ option.label }}
      </option>
    </select>
    <span class="error-message" v-if="isInvalid">{{ errorMessage }}</span>
  </div>
</template>

<script>
import clickOutside from '../utils/clickOutside';

export default {
  props: {
    value: [String, Array],
    options: Array,
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      preselectedOption: null,
      filter: '',
      errorMessage: null,
      isInvalid: false,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((o) => o.label.includes(this.filter));
    },
  },
  directives: {
    clickOutside,
  },
  watch: {
    isOpen: 'scrollAuto',
    filter: 'openOptions',
  },
  methods: {
    handleInvalid(evt) {
      this.errorMessage = evt.target.validationMessage;
      this.isInvalid = true;
    },
    handleBoxClick() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
        this.$refs.select.focus();
      }
    },
    closeSelect() {
      this.$refs.select.focus();
      this.isOpen = false;
    },
    keyDown() {
      if (this.isOpen) {
        const index = this.filteredOptions.indexOf(this.filteredOptions.find((o) => o.name === this.preselectedOption));
        this.preselectedOption = this.filteredOptions[Math.min(this.filteredOptions.length - 1, index + 1)].name;
      }
      this.isOpen = true;
      this.scrollAuto();
    },
    keyUp() {
      if (this.isOpen) {
        const index = this.filteredOptions.indexOf(this.filteredOptions.find((o) => o.name === this.preselectedOption));
        this.preselectedOption = this.filteredOptions[Math.max(0, index - 1)].name;
      }
      this.isOpen = true;
      this.scrollAuto();
    },
    updateValue(val) {
      if (this.multiple) {
        let response = null;
        response = Array.isArray(this.value) ? this.value : [];

        if (response.includes(val)) {
          response.splice(response.indexOf(val), 1);
        } else {
          response.push(val);
        }
        this.$emit('input', response);
      } else {
        this.$emit('input', val);
      }
      this.isInvalid = false;
    },
    onChange(val) {
      this.updateValue(val);
      this.preselectedOption = val;
      if (!this.multiple) {
        this.isOpen = false;
        this.$refs.select.focus();
      }
    },
    scrollAuto() {
      if (this.isOpen && this.preselectedOption) {
        // On attend la prochaine maj du dom pour faire scroller le dom de la box d'options (lié au v-if sur options)
        this.$nextTick(() => {
          this.$refs.optionsBox.scrollTo(0, this.$refs[`option${this.preselectedOption}`][0].offsetTop);
        });
      } else {
        this.filter = '';
      }
    },
    openOptions() {
      if (this.filter.length > 0) this.isOpen = true;
    },
    clearValue(val) {
      if (!this.multiple) {
        this.$emit('input', null);
        this.isOpen = false;
        this.$refs.select.focus();
      } else {
        this.onChange(val);
      }
    },
  },
};
</script>

<style lang="sass">
.app-select
  position: relative
  min-width: 200px
  height: 44px
  background-color: white
  text-align: left
  margin: 4px 0
  border: 1px solid $color-gray-20
  border-radius: $global-border-radius
  transition: all 0.2s ease-in-out
  margin: 4px 0
  cursor: pointer
  &:hover
    border-color: $color-gray-60
  &:focus
    outline: 0
    border-color: $color-primary-100
  &.is-invalid
    border-color: $color-secondary-100
  &.open
    border-color: $color-primary-100
    .box:after
      transform: rotate(45deg)
      border-color: $color-primary-100

  &.disabled
    background-color: $color-gray-10
    cursor: not-allowed
    &:hover
      border-color: $color-gray-20
    .result
      background-color: $color-gray-10 !important

  select
    width: 0
    height: 0
    opacity: 0

  .box
    position: relative
    padding: 12px 16px
    .filter
      width: 0
      outline: 0 !important
      border: 0 !important
      opacity: 0
      margin-left: -4px
      &.visible
        margin-left: 0.2rem
        width: 200px
        opacity: 1
    &::after
      content: ""
      position: absolute
      top: 50%
      right: 15px
      margin-top: -2px
      width: 5px
      height: 5px
      border: solid $color-gray-60
      border-width: 0 1px 1px 0
      transform-origin: center
      transform: rotate(-45deg)
      transition: all 0.2s ease-in-out
      &:hover
        border-color: darken($light-color, 10%)

    .result
      display: inline-flex
      margin-right: 8px
      font-size: 14px
      line-height: 22px
      background: $white
      .remove
        padding: 0 0.05rem 0 0.5rem
        color: $text-color
        font-size: 0.8rem
        cursor: pointer

    .empty
      display: inline-flex
      color: $color-gray-60

  .options
    position: absolute
    top: calc(100% + 8px)
    left: -1px
    right: -1px
    margin: 0
    padding: 0
    max-height: 200px
    list-style-type: none
    background: white
    border: 1px solid $color-gray-20
    border-radius: $global-border-radius
    overflow: hidden
    overflow-y: scroll
    z-index: 200
    li
      padding: 12px 16px
      transition: all 0.2s ease-in-out
      &.preselected
        background: $color-primary-10
      &.selected
        background: $color-primary-20

.select-enter-active, .select-leave-active
  transition: all 0.25s ease-in-out

.select-enter, .select-leave-to
  opacity: 0
  transform: translateY(-10px)

.error-message
  color: $color-secondary-100
</style>
