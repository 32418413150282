<!--
****************************************************************************************************
***                                          Radio                                               ***
****************************************************************************************************

Le composant radio est proche de celui en HTML5, c'est à dire une variable qui peut prendre 1 valeur.

*************
*** PROPS ***
*************
  - Value :
    C'est la valeur que prendra la variable dans v-model

    Exemple :
      <app-radio value="value1" v-model="answer">Label 1</app-radio>
      <app-radio value="value2" v-model="answer">Label 2</app-radio>
      <app-radio value="value3" v-model="answer">Label 3</app-radio>

-->
<template>
  <div class="app-radio" :class="{ auto: size === 'auto' }">
      <label class="radio-card" :class="[{ checked: input === value }, size]">
        <input type="radio"
          :checked="input === value"
          @change="onChange()"/>
        <span :class="{ empty: !$slots.default }" class="img-container">
          <slot></slot>
        </span>
        <span class="text-container">
          <div class="title">{{ title || value }}</div>
        </span>
      </label>
  </div>
</template>

<script>

export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    // Obligatoire
    input: [String, Array, Number],
    value: {
      type: String,
    },
    size: {
      type: String,
      default: 'small',
      validator: (val) => ['small', 'medium', 'auto'].includes(val),
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onChange() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style lang="sass">
.app-radio
  display: inline-block
  label
    background: $white
    border-radius: 10px
    border: 1px solid $white
    box-shadow: $medium-shadow
    padding: 13px 20px 13px 10px
    display: flex
    column-gap: 16px
    input
      width: 0
      height: 0
      position: absolute
      opacity: 0
    .text-container
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: center
      text-align: left
      .title
        color: $color-gray-70
    .img-container
      width: 30px
      display: flex
      align-items: center
      img, svg
        height: 100%
      &.empty
        display: none
    &:hover
      cursor: pointer
    &.checked
      box-shadow: $radio-button-shadow
      transition: box-shadow 0.4s
      *
        transition: filter 0.4s
    &:not(.checked) *
      filter: grayscale(1) opacity(0.7)
      transition: filter 0.4s

    &.auto
      width: 100%
    &.medium
      padding: 13px 13px 13px 16px
      height: 92px
      .img-container
        height: calc(100%-26px)

</style>
