<!--
****************************************************************************************************
***                                      Collapse                                                ***
****************************************************************************************************

Collapse

*************
*** PROPS ***
*************

  Exemple :
  <app-collapse v-model="activeTab" :name="1" title="Lorem 1">
    Lorem Ipsum
  <app-collapse/>

  <app-collapse v-model="activeTab" :name="2" title="Lorem 2">
    Lorem Ipsum
  <app-collapse/>

  <app-collapse v-model="activeTab" :name="3" title="Lorem 3">
    Lorem Ipsum
  <app-collapse/>

-->
<template>
  <div class="app-collapse" :class="[variant, { open: isOpen }]">
    <div class="header" @click="onChange()">
      <div v-if="variant === 'list'" class="illustration">
        <slot name="image"></slot>
      </div>
      <p v-if="variant === 'faq'">{{ title }}</p>
      <h3 v-else>{{ title }}</h3>
      <div class="icon">{{ isOpen ? '-' : '+' }}</div>
    </div>
    <div class="content" ref="content" :style="{ height: isOpen ? `${$refs.content.scrollHeight}px` : 0 }">
      <div><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [String, Number],
    name: [String],
    title: [String],
    variant: {
      default: 'faq',
      validator: (val) => ['faq', 'list'].includes(val),
    },
  },
  computed: {
    isOpen() {
      return this.input === this.name;
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.name === this.input ? null : this.name);
    },
  },
};
</script>

<style lang="sass">
.app-collapse
  .header
    cursor: pointer
    display: flex
    column-gap: 1rem
    align-items: center
    color: $text-dark-neutral-color
    .icon
      color: $main-color
      font-weight: bold
      font-size: 30px

  .content
    overflow: hidden
    transition: all 0.3s linear

  &.faq
    padding: 20px
    .header
      margin-bottom: 14px
      padding-bottom: 16px
      border-bottom: 1px solid $color-primary-50
      justify-content: space-between
      @include text-bold
    &.open
      background-color: $color-primary-20
  &.list
    padding: 12px 16px
    background: $bg-light-color
    border-radius: 20px
    .header
      justify-content: flex-start
      .illustration
        width: 61px
        height: 61px
        display: flex
        justify-content: center
        align-items: center
        img, svg
          width: 100%
          height: 100%
      h3
        flex-grow: 2
    .content
      padding-left: 80px
    &.open .content
      margin-bottom: 20px

  @media (max-width: 768px)
    h3
      font-size: 18px

    &.list
      .content
        padding-left: 0
</style>
