import Vue from 'vue';
import Router from 'vue-router';
import Demo from './views/Demo.vue';
import WhoWeAre from './views/WhoWeAre.vue';
import Contact from './views/Contact.vue';
import FAQ from './views/FAQ.vue';
import Partner from './views/Partner.vue';
import Team from './views/Team.vue';
import WhyChooseUs from './views/WhyChooseUs.vue';
import SmilePhotoAligner from './views/SmilePhotoAligner.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/demo',
      name: 'demo',
      component: Demo,
    },
    {
      path: '/qui-sommes-nous',
      name: 'who-we-are',
      component: WhoWeAre,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/foire-aux-questions',
      name: 'faq',
      component: FAQ,
    },
    {
      path: '/partenaire',
      name: 'partner',
      component: Partner,
    },
    {
      path: '/equipe',
      name: 'team',
      component: Team,
    },
    {
      path: '/pourquoi-nous-choisir',
      name: 'why-choose-us',
      component: WhyChooseUs,
    },
    {
      path: '/transformez-votre-sourire-avec-ealigner',
      name: 'smile-photo-aligner',
      component: SmilePhotoAligner,
    },
    {
      path: '*',
      beforeEnter: (to, from, next) => {
        next({ name: 'who-we-are' });
      },
    },
    // Exemple de route pour une page connectée
    // {
    //   path: '/une-page-connectée',
    //   name: 'logged-page',
    //   meta: { requiresAuth: true },
    // }
  ],
});
