<template>
  <section class="steps-block">
    <!-- Titre du block -->
    <h2>
      <app-subtitle>Comment ça marche</app-subtitle>
      {{ title }}
    </h2>
    <!-- Contenu du block -->
    <ul :class="[`steps-block-content`, type]">
      <!-- Images ajoutées au fur et à mesure des étapes -->
      <div class="img-container">
        <img src="~@/assets/img/illustrations/smiling-brunette-on-dentist-chair.png" alt=""/>
      </div>
      <div class="img-container">
        <img src="~@/assets/img/illustrations/male-dentist-showing-teeth-radio.png" alt=""/>
      </div>

      <!-- Cartes Étapes -->
      <li class="step-card" v-for="(step, index) in steps" :key="step.title">
        <div class="icon-container">
          <component :is="step.icon" />
        </div>
        <div class="step-card-title">
          <h3>
            <app-subtitle>étape {{ index + 1 }} {{ step.subtitle ? `- ${step.subtitle}` : null }}</app-subtitle>
            {{ step.title }}
          </h3>
        </div>
        <div class="step-card-description">
          <p>{{ step.description }}</p>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'steps-block',
  props: {
    // Format [{ title, subtitle (optionnel), description, icon }] attendu
    steps: {
      type: Array,
      required: true,
      validator: (val) => val.length === 4,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      observer: null,
    };
  },
  mounted() {
    this.initIntersectionObserver();
  },
  destroyed() {
    this.observer.disconnect();
  },
  computed: {
    type() {
      return `steps-${this.steps.length}`;
    },
  },
  methods: {
    // Initialise l'observateur d'intersection pour animer chaque étape lors de leur apparition
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: [0, 0.25],
      };
      this.observer = new IntersectionObserver(this.handleIntersection, options);
      this.$el.querySelectorAll('.step-card').forEach((step) => {
        this.observer.observe(step);
      });
    },
    // Modifie le style des éléments observés selon leur intersection avec le viewport
    handleIntersection(entries) {
      entries.forEach((entry) => {
        const { target } = entry;
        if (entry.intersectionRatio > 0.1) {
          target.style.opacity = 1;
          target.style.transform = 'scale(1)';
        } else {
          target.style.opacity = 0;
          target.style.transform = 'scale(0.9)';
        }
      });
    },
  },
};
</script>

<style lang="sass">
.steps-block
  text-align: center
  background: $bg-light-color
  padding: 100px 120px
  > h2
    margin: 0 auto 70px auto
    max-width: 700px
  .steps-block-content
    max-width: 1200px
    margin: auto
    padding: 0
    display: grid
    &.steps-3
      grid-template-areas: "step-1 illustration-1" "illustration-2 step-2" "illustration-2 step-3"
      li.step-card
        .icon-container
        &:nth-child(3)
          margin-top: 100px
      .img-container
        img
        &:first-child
          height: 520px
          margin-bottom: 70px
    &.steps-4
      grid-template-areas: "step-1 illustration-1" "step-2 illustration-1" "illustration-2 step-3" "illustration-2 step-4"
    grid-template-columns: auto auto
    gap: 40px
    list-style-type: none
    li.step-card
      background: $white
      padding: 40px 30px
      text-align: left
      height: fit-content
      position: relative
      opacity: 0
      transform: scale(.9)
      transition: ease-in-out .400s .2s
      .icon-container
        width: 60px
        height: 60px
        border-radius: 50%
        background: $main-color
        border: 2px solid $white
        display: flex
        align-items: center
        justify-content: center
        position: absolute
        top: 0
        left: 0
        transform: translate(-50%, -50%)
        svg
          height: 30px
      .step-card-title
        h3
          margin-top: 0
        .app-subtitle
          text-align: left
      &:nth-child(3), &:nth-child(4)
        border-radius: 20px 0 20px 0
      &:nth-child(6), &:nth-child(5)
        border-radius: 0 20px 0 20px
      &:nth-child(3)
        grid-area: step-1
      &:nth-child(4)
        grid-area: step-2
      &:nth-child(5)
        grid-area: step-3
      &:nth-child(6)
        grid-area: step-4
    .img-container
      height: 100%
      width: 80%
      min-width: 350px
      position: relative
      z-index: 0
      img
        height: 100%
        width: 100%
        border-radius: 40px 0 40px 0
        object-fit: cover
        z-index: 0
      &::after
        position: absolute
        z-index: -1
      &:first-child
        grid-area: illustration-1
        justify-self: end
        &::after
          content: url("data:image/svg+xml,%3Csvg viewBox='0 0 238 260' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M211.195 258.129L2.28116 108.452L236.362 2.36582L211.195 258.129Z' fill='%236BBB32' stroke='%236BBB32' stroke-width='2'/%3E%3C/svg%3E")
          width: 180px
          bottom: 0
          right: 0
          transform: translate(20%, 40%)
      &:nth-child(2)
        grid-area: illustration-2
        &::after
          content: url("data:image/svg+xml,%3Csvg viewBox='0 0 134 153' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M132.334 151.099L8.51607 119.091L98.1446 27.8658L132.334 151.099Z' fill='%236BBB32' stroke='%236BBB32' stroke-width='2'/%3E%3Cpath d='M93.0083 2.88256L126.056 126.427L2.53998 93.2751L93.0083 2.88256Z' stroke='%23559528' stroke-width='2'/%3E%3C/svg%3E")
          width: 134px
          bottom: 0
          left: 0
          transform: translate(-40%, 20%)

    img.container:nth-child(2), .step-card:nth-of-type(4n+3)
      margin-top: 50px

@media (max-width: 768px)
  .steps-block
    padding: 70px 10vw
    .steps-block-content
      &.steps-3
        grid-template-areas: "illustration-1" "step-1" "illustration-2" "step-2" "step-3"
        li.step-card
          .icon-container
          &:nth-child(3)
            margin-top: 0
        .img-container
          img
          &:first-child
            margin-bottom: 0
      &.steps-4
        grid-template-areas: "illustration-1" "step-1" "step-2" "illustration-2" "step-3" "step-4"
      grid-template-columns: auto
      justify-items: center
      row-gap: 50px
      .img-container
        min-width: unset
        width: 100%
        img
          width: 100%
          min-width: unset
        &:first-child, &:nth-child(2)
          &::after
            content: ''
      li.step-card
        &:nth-of-type(4n), &:nth-of-type(4n+3)
          border-radius: 20px 0 20px 0

</style>
