<template>
  <section class="map-block">
    <div class="map-container">
      <div id="map" />
      <div class="map-info-container">
        <div class="image-container">
          <img src="~@/assets/img/illustrations/ealigner-headquarters.jpg" alt="Siège social d'E-Aligner" />
        </div>
        <div class="map-info-content">
          <h3>Notre siège social E-Aligner</h3>
          <p>
            La Filature Numérique<br/>
            24 Bd Pierre Lecoq<br/>
            49300 Cholet
          </p>
          <p><strong>Téléphone :</strong> 02 41 46 93 78</p>
          <p><strong>Email :</strong> <a href="mailto:contact@ealigner.com">contact@ealigner.com</a></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export default {
  name: 'map-block',
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.setMap();
  },
  methods: {
    setMap() {
      // Calcule le décalage à appliquer au centre de la carte
      const isMobile = window.innerWidth <= 768;
      const centerOffset = isMobile ? 0 : this.$el.querySelector('.map-container').getBoundingClientRect().width * 0.10 * 0.0001;

      // Créé la carte
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_KEY;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: process.env.VUE_APP_MAPBOX_STYLE_URL,
        center: [-0.8666529153426119 + centerOffset, 47.04983984142447], // avoir EAligner décalée à gauche
        zoom: 14, // starting zoom,
      });

      // Ajoutes les controles de navigation (zooms, etc.)
      this.map.addControl(new mapboxgl.NavigationControl());
    },
  },
};
</script>

<style lang="sass">
.map-block
  padding: 100px 5vw
  background-color: $white
  .map-container
    position: relative
    width: 100%
    height: 550px
    #map
      position: absolute
      top: 0
      width: 100%
      height: 100%
    .map-info-container
      position: absolute
      top: 35px
      bottom: 35px
      right: 55px
      width: 30%
      background-color: $main-color
      color: $white
      border-radius: 24px 0 24px 0
      overflow: hidden
      .image-container
        height: 50%
        img
          object-fit: cover
          height: 100%
      .map-info-content
        height: 50%
        padding: 30px
        display: flex
        flex-direction: column
        justify-content: space-between
        h3, p, a
          color: $white
          margin: 0
        a
          @include text

@media (max-width: 768px)
  .map-block
    padding-top: 30px
    padding-bottom: 70px
    .map-container
      height: calc(300px + 500px)
      #map
        height: 300px
        z-index: 0
        div
          z-index: 0
        div[class^="mapboxgl-ctrl-bottom"]
          bottom: 24px
      .map-info-container
        top: calc(300px - 24px)
        right: 0
        left: 0
        width: 100%
        height: 500px
        z-index: 1
        .image-container
          height: 40%
        .map-info-content
          height: 60%
</style>
