<!--
****************************************************************************************************
***                                       Subtitle                                               ***
****************************************************************************************************

Titre secondaire

  Exemple :
  <app-subtitle>Titre 2<app-subtitle/>

-->
<template>
  <p class="app-subtitle"><slot></slot></p>
</template>

<script>
export default {
};
</script>

<style lang="sass">
.app-subtitle
  position: relative
  text-transform: uppercase
  text-align: center
  margin: 0 auto 0.3rem auto
  @include subtitle
</style>
