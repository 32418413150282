<!--
****************************************************************************************************
***                                         Input                                               ***
****************************************************************************************************

Champ texte simple ou multiligne

*************
*** PROPS ***
*************
  - type :
    Type du champ

  Exemple :
  <app-input type="text" v-model="answer4"/>
  <app-input type="textarea" v-model="answer4"/>

-->
<template>
  <div class="app-input">
    <textarea
      ref="textarea"
      v-if="type === 'textarea'"
      class="app-input-input"
      contenteditable="true"
      :rows="rows"
      :class="{ invalid: isInvalid }"
      :value="value"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      @invalid.prevent="handleInvalid"
      @input="$emit('input', $event.target.value)"
    />
    <input
      v-else class="app-input-input"
      :class="{ invalid: isInvalid }"
      :type="type"
      :value="value"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :minlength="minlength"
      :maxlength="maxlength"
      @invalid.prevent="handleInvalid"
      @input="$emit('input', $event.target.value)"
    />
    <div v-if="errorText" class="error-text">{{ errorText }}</div>
  </div>
</template>

<script>

export default {

  props: {
    value: [String, Array, Number],
    type: {
      default: 'text',
      validator: (val) => ['text', 'number', 'email', 'textarea'].indexOf(val) !== -1,
    },
    autocomplete: {
      default: 'off',
    },
    placeholder: {
      default: 'Placeholder',
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
    minlength: {
      type: String,
    },
    maxlength: {
      type: String,
    },
    rows: {
      type: Number,
    },
  },

  data() {
    return {
      errorText: this.errorMessage,
      isInvalid: !!this.errorMessage,
    };
  },

  mounted() {
    if (this.type === 'textarea' && !this.$props.rows) {
      this.$refs.textarea.setAttribute('style', `height:${this.$refs.textarea.scrollHeight}px;overflow-y:hidden;`);
      this.$refs.textarea.addEventListener('input', this.onInput, false);
    }
  },

  computed: {
    input() {
      return this.value;
    },
  },

  watch: {
    errorMessage() {
      if (this.errorMessage) {
        this.isInvalid = true;
        this.errorText = this.errorMessage;
      } else {
        this.isInvalid = false;
        this.errorText = null;
      }
    },
  },

  methods: {
    handleInvalid(evt) {
      this.errorText = evt.target.validationMessage;
      this.isInvalid = true;
    },

    onInput() {
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
    },
  },
};
</script>

<style lang="sass">
.app-input
  width: 100%
  min-width: 200px
  margin: 4px 0
  position: relative

  .app-input-input
    width: 100%
    padding: 12px 16px
    background: white
    font-size: 14px
    line-height: 22px
    border-radius: $global-border-radius
    border: 1px solid $color-gray-20
    transition: all 0.2s ease-in-out
    @include text
    &::placeholder
      color: $color-gray-60
    &:hover:not(:disabled)
      border-color: var(--main-color, $main-color)
    &:focus:not(:disabled)
      outline: 0
      border-color: var(--main-color, $main-color)
    &.invalid
      border-color: $color-secondary-100
    &:disabled
      background-color: $color-gray-10
      cursor: not-allowed

  textarea
    resize: none
    line-height: 22px
    min-height: 100px

  .error-text
    color: $color-secondary-100
    text-align: left
</style>
