<template>
  <div class="screen contact">
    <section class="title-block">
      <div class="text-container">
        <h1>Vous avez <strong>des questions ?</strong><br/>Contactez nous !</h1>
        <p>
          Une question concernant nos appareils orthodontique ou votre traitement ? Vous pouvez nous poser toutes les questions que vous désirez, nous vous répondront le plus rapidement possible. Si vous avez besoin d'une aide immédiate, vous pouvez nous joindre par teléphone au : <a href="tel:0241469378">02 41 46 93 78</a>
        </p>
      </div>
      <div class="img-container">
        <img src="~@/assets/img/illustrations/sourire-ealigner-2.png" alt=""/>
      </div>
    </section>
    <section class="contact-form-block">
      <h2>
        <app-subtitle>Contact</app-subtitle>
        Notre formulaire de contact
      </h2>
      <form @submit.prevent="sendContactEmail">
        <div>
          <app-label required>Vous êtes ?</app-label>
          <app-select required v-model="occupation" :options="occupationOptions" :disabled="isSendingEmail"></app-select>
        </div>
        <div>
          <app-label required>Nom</app-label>
          <app-input required v-model="lastname" :disabled="isSendingEmail" placeholder="Nom de famille"/>
        </div>
        <div>
          <app-label required>Prénom</app-label>
          <app-input required v-model="firstname" :disabled="isSendingEmail" placeholder="Prénom"/>
        </div>
        <div>
          <app-label required>Votre email</app-label>
          <app-input required v-model="email" :disabled="isSendingEmail" placeholder="Email"/>
        </div>
        <div>
          <app-label required>L'objet de votre message</app-label>
          <app-input required v-model="subject" :disabled="isSendingEmail" placeholder="Objet du message" />
        </div>
        <div>
          <app-label required>Votre message</app-label>
          <app-input required type="textarea" v-model="text" :disabled="isSendingEmail" placeholder="Votre message"/>
        </div>
        <app-button type="submit" :loading="isSendingEmail">Envoyer votre message</app-button>
      </form>
    </section>
    <MapBlock />
  </div>
</template>

<script>
import auth from '@/services/auth';
import MapBlock from '@/components/MapBlock.vue';

export default {
  name: 'contact',
  components: {
    MapBlock,
  },
  data() {
    return {
      occupation: '',
      firstname: '',
      lastname: '',
      email: '',
      subject: '',
      text: '',
      isSendingEmail: false,

      occupationOptions: [
        { name: 'Chirurgien dentiste', label: 'Chirurgien dentiste' },
        { name: 'Orthodentiste', label: 'Orthodentiste' },
        { name: 'Laboratoire de prothèses', label: 'Laboratoire de prothèses' },
        { name: 'Particulier', label: 'Particulier' },
      ],
    };
  },
  methods: {
    async sendContactEmail() {
      this.isSendingEmail = true;
      try {
        // Envoi de l'email
        await auth.sendContactEmail({
          occupation: this.occupation,
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          subject: this.subject,
          text: this.text,
        });
        // Notification à l'utilisateur
        this.$notification.show({ text: 'Votre message a été envoyé avec succès !' });
        // Reset du formulaire
        this.occupation = null;
        this.firstname = null;
        this.lastname = null;
        this.email = null;
        this.subject = null;
        this.text = null;
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de l\'envoi du message. Veuillez vérifier votre connexion internet',
        });
      }
      this.isSendingEmail = false;
    },
  },
};
</script>

<style lang="sass">
.contact.screen
  width: 100vw
  margin: 0
  .title-block
    display: flex
    background: $white
    padding: 70px 100px 130px 100px
    justify-content: space-between
    align-items: center
    .text-container
      max-width: 40%
      position: relative
      h1
        strong
          @include h1
          color: $main-color
      &::before
        @include title-background
    .img-container
      max-width: 50%
      position: relative
      z-index: 0
      img
        border-radius: 40px 40px 40px 0
        z-index: 0
      &::after
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 139 144' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.7' d='M2.24739 103.45L103.899 2.04968L137.519 141.637L2.24739 103.45Z' stroke='%236BBB32' stroke-width='2'/%3E%3C/svg%3E")
        display: block
        width: 139px
        position: absolute
        bottom: 0
        right: 0
        transform: translate(30%, -10%)
        z-index: -1

  .contact-form-block
    width: 100vw
    padding: 100px
    background: $bg-light-color
    text-align: center
    h2
      margin-bottom: 90px
    form
      max-width: 1200px
      margin: auto
      display: flex
      gap: 44px
      justify-content: space-between
      flex-wrap: wrap
      > div
        flex-grow: 2
        &:first-child, &:nth-child(2), &:nth-child(3)
          min-width: 30%
        &:nth-child(4), &:nth-child(5)
          min-width: 40%
        &:nth-child(6)
          width: 100%
      .app-button
        margin: auto
        width: 100%
        max-width: 370px
@media (max-width: 768px)
  .contact.screen
    .title-block
      flex-direction: column-reverse
      padding: 0
      padding-bottom: 100px
      .img-container
        width: 100vw
        max-width: none
        margin-bottom: 30px
        img
          width: 100%
          border-radius: 0
        &::after
          content: ''
      .text-container
        padding: 0 5vw
        width: 100%
        max-width: none
    .contact-form-block
      padding: 30px 5vw 90px 5vw
      h2
        margin-bottom: 30px
      form
        gap: 24px
</style>
