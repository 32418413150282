<template>
  <div class="screen-faq screen">
    <h1>Toutes les questions fréquentes</h1>
    <div class="radio-container">
      <app-radio v-model="category" value="questionsEligibilite" title="Eligibilité">
        <img src="~@/assets/img/illustrations/eligibilite.png" alt=""/>
      </app-radio>
      <app-radio v-model="category" value="questionsGenerales" title="Questions générales">
        <img src="~@/assets/img/illustrations/general.png" alt=""/>
      </app-radio>
      <app-radio v-model="category" value="questionsTraitementEaligner" title="Le traitement EAligner">
        <img src="~@/assets/img/illustrations/traitement.png" alt=""/>
      </app-radio>
      <app-radio v-model="category" value="questionsApprehensions" title="Apprehension">
        <img src="~@/assets/img/illustrations/apprehension.png" alt=""/>
      </app-radio>
    </div>

    <div class="questions">

      <div class="image">
        <img :src="require(`@/assets/img/illustrations/photos/${getImage()}`)" alt="">
      </div>

      <div class="grid-container">
        <h3>{{ faq[category].titre }}</h3>
        <div v-for="question in faq[category].questions" :key="question.id">
          <app-collapse :title="question.question" v-model="activeCollapse" :name="question.id">
            {{ question.reponse }}
          </app-collapse>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'FAQ',
  data() {
    return {
      // données dynamiques
      category: 'questionsEligibilite',
      activeCollapse: null,

      // données statiques
      faq: {
        questionsEligibilite: {
          titre: "Questions sur l'égibilité",
          questions: [
            {
              id: 'eligibilite1',
              image: 'eligibilite1.jpg',
              question: 'Suis-je un bon candidat pour un traitement par gouttières d\'alignement E-ALIGNER ?',
              reponse: 'Renseignez-vous auprès de votre praticien dentaire. Lui seul est habilité à vous prescrire un traitement par gouttières d\'alignement transparente',
            },
            {
              id: 'eligibilite2',
              image: 'eligibilite2.jpg',
              question: 'Quel est l\'âge minimum pour suivre un traitement E-ALIGNER ?',
              reponse: 'La solution E-ALIGNER répond parfaitement au traitement de la majorité des malocclusions chez l\'adulte et l\'adolescent en quête d\'un sourire parfait. Dans le cas de l\'adolescent, le praticien reste seul juge de la pertinence d\'un traitement E-ALIGNER',
            },
            {
              id: 'eligibilite3',
              image: 'eligibilite3.jpg',
              question: 'J\'ai actuellement des bagues. Puis-je changer pour des gouttières d\'alignement E-ALIGNER ?',
              reponse: 'Consultez votre praticien, lui seul sera en mesure de déterminer si la possibilité de changer de traitement est adapté ou non.',
            },
            {
              id: 'eligibilite4',
              image: 'eligibilite4.jpg',
              question: 'Je suis fumeur. Est-il possible de fumer en portant mes gouttières ?',
              reponse: 'Il est fortement recommandé de retirer ses gouttières d\'alignement pour fumer au risque d\'altérer la transparence de celles-ci.',
            },
          ],
        },
        questionsGenerales: {
          titre: 'Questions générales',
          questions: [
            {
              id: 'general1',
              image: 'general1.jpg',
              question: 'Quels sont les avantages d\'E-ALIGNER ?',
              reponse: 'Nos gouttières d\'alignement, conçues par un orthodontiste, sont quasiment invisibles et parfaitement amovibles. Contrairement aux appareils traditionnels, vous pouvez manger ce que vous voulez en les retirant simplement. Grace à notre logiciel 3D, vous pouvez, en compagnie de votre praticien, visualiser l\'ensemble de votre traitement jusqu\'au résultat final.',
            },
            {
              id: 'general2',
              image: 'general2.jpg',
              question: 'Combien me coûte un traitement par gouttières d\'alignement E-ALIGNER ?',
              reponse: 'Comme tout traitement dentaire, son coût est généralement établi en fonction de la complexité du plan de traitement. Contactez votre praticien qui sera en mesure de vous répondre au mieux.',
            },
            {
              id: 'general3',
              image: 'general3.jpg',
              question: 'Quelle est la durée du traitement E-ALIGNER ?',
              reponse: 'E-ALIGNER propose un traitement au plus juste dont la durée moyenne varie entre 3 à 12 mois selon la complexité du cas clinique.',
            },
            {
              id: 'general4',
              image: 'general4.jpg',
              question: 'Quelle est la composition des gouttières d\'alignement E-ALIGNER ?',
              reponse: 'Les gouttières d\'alignement sont des dispositifs médicaux sur mesure, créées sur la base d\'un matériel thermoplastique ultrarésistant et de spécificité médicale. Elles sont conçues sans Phtalate et sans Bisphénol et conformes aux normes ONI EN ISO 9001 et ISO 13485.',
            },
            {
              id: 'general5',
              image: 'general5.jpg',
              question: 'Où et comment sont fabriquées les gouttières d\'alignement E-ALIGNER ?',
              reponse: 'Toutes les gouttières d\'alignement E-ALIGNER sont exclusivement fabriquées et conditionnées en France. E-ALIGNER fabrique lui-même l\'ensemble de ses gouttières dans son laboratoire Français. Chacune des gouttières est conçues minutieusement à la main et sur-mesure par notre équipe de prothésistes dentaires, pour que vos gouttières soient le plus discrètes et confortables possible.',
            },
            {
              id: 'general6',
              image: 'general6.jpg',
              question: 'Comment financer mon traitement E-ALIGNER ?',
              reponse: 'La sécurité sociale ou votre mutuelle peuvent prendre partiellement ou complétement en charge votre traitement E-ALIGNER que vous soyez adulte ou adolescent. Rapprochez-vous de ces organismes afin d\'obtenir une estimation.',
            },
          ],
        },
        questionsTraitementEaligner: {
          titre: 'Questions sur le traitement E-ALIGNER',
          questions: [
            {
              id: 'traitement1',
              image: 'traitement1.jpg',
              question: 'Combien de rendez-vous chez mon praticien seront nécessaires durant mon traitement E-ALIGNER ?',
              reponse: 'La fréquence des consultations est diminuée par rapport à un traitement par bagues. Le praticien vous proposera un rendez-vous pour la mise en place de la première gouttière d\'alignement puis à des étapes clefs en fonction de votre plan de traitement. Le nombre de visites reste au jugement du praticien.',
            },
            {
              id: 'traitement2',
              image: 'traitement2.jpg',
              question: 'Combien de temps dois-je porter mes gouttières d\'alignement E-ALIGNER ?',
              reponse: 'Chaque gouttière d\'alignement est prévue pour être remplacée toutes les 2 à 3 semaines et portée au minimum 22 heures par jour. Le respect des durées et des périodes de port préconisées par votre praticien, vous assure de la réussite de votre traitement.',
            },
            {
              id: 'traitement3',
              image: 'traitement3.jpg',
              question: 'Quels sont les moyens les plus efficaces pour nettoyer ma gouttière d\'alignement ?',
              reponse: 'Brossez vos gouttières après chaque utilisation au dentifrice blanc (évitez les colorants pouvant altérer la transparence des gouttières), puis rincez-les à l\'eau froide. ATTENTION, NE JAMAIS UTILISER D\'EAU CHAUDE cela risque de déformer vos gouttières et de les rendre inefficaces. Une solution bucco-dentaire peut aussi être adaptée.',
            },
            {
              id: 'traitement4',
              image: 'traitement4.jpg',
              question: 'J\'ai perdu ou j\'ai cassé une gouttière, que dois-je faire ?',
              reponse: 'Contactez votre praticien dentaire, lui seul est habilité à vous prescrire une nouvelle gouttière. Nous vous remplacerons votre gouttière, à l\'étape à laquelle vous êtes rendu. Attention, le remplacement de votre appareil perdu ou endommagé, engagera des frais supplémentaires.',
            },
            {
              id: 'traitement5',
              image: 'traitement5.jpg',
              question: 'Puis-je mâcher du chewing-gums en portant mes gouttières ?',
              reponse: 'A la fin de chaque période, il est conseillé de conserver son jeu de gouttières afin de pouvoir réutiliser celles-ci temporairement en cas de perte ou de casse de la gouttière en cours. A cet effet, E-ALIGNER vous fournit des boites pour les ranger.',
            },
            {
              id: 'traitement6',
              image: 'traitement6.jpg',
              question: 'A quoi sert la gouttière de contention prescrite par mon praticien à la fin de mon traitement E-ALIGNER ? La contention est-elle une étape obligatoire ?',
              reponse: 'En fin de traitement, le port de gouttières de contention est fortement conseillé au risque de récidive de l\'anomalie initiale ou d\'apparition d\'une nouvelle pathologie. Elles permettent de stabiliser le résultat acquis. Une durée de port de 8h/jour, par exemple en nocturne, est suffisante.',
            },
          ],
        },
        questionsApprehensions: {
          titre: 'Appréhensions',
          questions: [
            {
              id: 'apprehension1',
              image: 'apprehension1.jpg',
              question: 'Le traitement E-ALIGNER est-il douloureux ?',
              reponse: 'Il est possible de ressentir une pression dentaire après la mise en place d\'une nouvelle gouttière. Cette pression indique que les gouttières d\'alignement agissent correctement. La gêne disparaitra rapidement. En cas de douleur ou réaction anormale contactez votre praticien.',
            },
            {
              id: 'apprehension2',
              image: 'apprehension2.jpg',
              question: 'Les gouttières d\'alignement E-ALIGNER affectent-elles l\'élocution ?',
              reponse: 'La majorité des patients ne constatent aucun effet sur l\'élocution. Cependant il est possible que durant la phase d\'adaptation vous puissiez rencontrer une gêne qui disparait très rapidement.',
            },
            {
              id: 'apprehension3',
              image: 'apprehension3.jpg',
              question: 'Existe-t-il des restrictions alimentaires pendant le traitement ?',
              reponse: 'Grâce à vos gouttières amovibles, vous pouvez manger et boire ce qu\'il vous plait pendant le traitement. Cependant, il est conseillé de retirer vos gouttières pour boire café ou thé car cela risque potentiellement d\'altérer la transparence de vos gouttières.',
            },
          ],
        },
      },
    };
  },
  watch: {
    category: 'resetCollapse',
  },
  methods: {
    getImage() {
      const question = this.faq[this.category].questions.find((obj) => obj.id === this.activeCollapse);
      if (question) {
        return question.image;
      }
      return 'eligibilite1.jpg';
    },
    resetCollapse() {
      this.activeCollapse = null;
    },
  },
};
</script>

<style lang="sass">
.screen-faq
  display: flex
  background: $bg-light-color

  align-items: center
  flex-direction: column

  h1
    margin-top: 2em
    text-align: center
    color: $main-color

  .radio-container
    margin: 0 0 4em 0
    display: flex
    align-items: stretch
    justify-content: center
    flex-wrap: wrap
    column-gap: 10px
    row-gap: 10px

  .image
    margin: 0 1em
    overflow: hidden
    border-radius: 10% 0 10% 0

    img
      object-fit: cover
      height: 100%

  .grid-container
    margin: 0 1em

  .questions
    margin-bottom: 6em

@media (min-width: 768px)
  .screen-faq
    .questions
      display: flex //image et questions alignées
      float: left
      width: 80%

      .image
        width : 40%
        height : 540px
        position: sticky
        top: 10%

      .grid-container
        width: 60%

    h3
      margin-top: 0
      padding-left: 1em
      text-align: left

@media (max-width: 768px)
  .screen-faq
    margin-top: 5em

    h3
      text-align: center
</style>
