<template>
  <section id="app" :class="{ 'dpi-theme': isDpiScreen }">
    <top-nav :isDpiScreen="isDpiScreen"></top-nav>
    <router-view/>
    <footer-nav :isDpiScreen="isDpiScreen" />

    <!-- Global basics components integration -->
    <app-message/>
    <app-notifications/>
  </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import FooterNav from '@/components/FooterNav.vue';

export default {
  name: 'app',
  components: {
    TopNav,
    FooterNav,
  },
  data() {
    return {
      isDpiScreen: this.$route.name === 'partner',
    };
  },
  watch: {
    $route() {
      this.isDpiScreen = this.$route.name === 'partner';
    },
  },
  methods: {
    // Permet d'avoir la vraie valeur de l'écran, sans la place occupée par le navigateur sur mobile
    adaptVhInCss() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  created() {
    window.addEventListener('resize', this.adaptVhInCss);
  },
  destroyed() {
    window.removeEventListener('resize', this.adaptVhInCss);
  },
};
</script>
<style lang="sass">
@import "@/assets/sass/app.sass"

#app
  background: $white
  width: 100%
  overflow-x: hidden
  --header-height: calc(42px + 57px)
  &.dpi-theme // Permet d'ajuster toute la page au thème DPI
    --background-color: #1D2A3F
    --main-color: #179AC1
    --dark-main-color: #1D2A3F
    --hover-color: #BAECFD
  .screen
    margin-top: var(--header-height)

@media (max-width: 1200px)
  #app
   --header-height: calc(40px + 6vw)

@media (max-width: 768px)
  #app
    .top-nav
      z-index: 10
    .screen
      z-index: 5
</style>
