import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const getSlideList = async () => {
  try {
    const response = await axios.get(`${API_URL}/slides`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createSlide = async (rank, linkUrl, file) => {
  const formData = new FormData();
  formData.append('rank', +rank);
  if (file) formData.append('file', file);
  if (linkUrl) formData.append('linkUrl', linkUrl);
  try {
    const response = await axios.post(
      `${API_URL}/slides`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateSlide = async (slideId, linkUrl, file) => {
  const formData = new FormData();
  if (file) formData.append('file', file);
  if (linkUrl) formData.append('linkUrl', linkUrl);
  try {
    const response = await axios.put(
      `${API_URL}/slides/${slideId}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteSlide = async (slideId) => {
  try {
    const response = await axios.delete(`${API_URL}/slides/${slideId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const api = {};

api.getSlideList = getSlideList;
api.createSlide = createSlide;
api.updateSlide = updateSlide;
api.deleteSlide = deleteSlide;

export default api;
