<!--
****************************************************************************************************
***                                         Button                                               ***
****************************************************************************************************

Un bouton pour toutes les occasions.

*************
*** PROPS ***
*************
  - type : button ou submit
    Type du bouton

  - size : small ou medium ou large
    Taille du bouton

  - look : primary ou second
    Style du bouton

  - loading
    Présence du loader ou non

  Exemple :
  <app-button size="small" look="second" :loading="true">Bouton petit avec loader</app-button>
-->
<template>
  <button class="app-button" :type="type" :class="[look, `bg-${background}`]" @click="handleClick">
    <span v-if="loading" class="loader">
      <span></span>
      <span></span>
      <span></span>
    </span>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      default: 'button',
      validator: (val) => ['button', 'submit'].indexOf(val) !== -1,
    },
    look: {
      default: 'primary',
      validator: (val) => ['primary', 'secondary'].indexOf(val) !== -1,
    },
    background: {
      default: 'light',
      validator: (val) => ['light', 'dark'].indexOf(val) !== -1,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleClick(evt) {
      if (!this.loading) {
        this.$emit('click', evt);
      }
    },
  },
};
</script>

<style lang="sass">
.app-button
  display: inline-flex
  align-items: center
  justify-content: center
  margin: 0.2rem
  padding: 13px 20px
  background: $main-color
  color: white
  border: 0
  border-radius: $global-border-radius
  transition: all ease-in-out 0.2s
  cursor: pointer
  @include text-button
  &:focus
    outline: 0

  &.primary.bg-light
    background: var(--main-color, $main-color)
    color: $white
    &:hover
      background: var(--hover-color, $color-primary-40)
      color: var(--dark-main-color, $dark-main-color)
  &.primary.bg-dark
    background: $dark-main-color
    color: $white
    &:hover
      background: #77CE39

  &.secondary
    background: $color-gray-20
    color: $color-gray-80
    &:hover
      background: #F2F4F1
      color: $text-color

  .loader
    display: inline-block
    position: relative
    width: 40px
    height: 8px

  .loader span
    display: inline-block
    position: absolute
    left: 0
    width: 5px
    background: #fff
    animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite

  .loader span:nth-child(1)
    left: 0
    animation-delay: -0.24s

  .loader span:nth-child(2)
    left: 10px
    animation-delay: -0.12s

  .loader span:nth-child(3)
    left: 20px
    animation-delay: 0

  @keyframes loader
    0%
      top: 0
      height: 180%

    50%, 100%
      top: 0
      height: 100%

</style>
