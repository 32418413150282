<template>
  <div class="screen who-we-are">
    <section class="header-section">
      <div class="text-container">
        <h1>
          E-Aligner est la solution invisible <strong>d’alignement dentaire</strong> 100% française
        </h1>
      </div>
      <div class="slide-container">
        <app-spinner v-if="isLoading" />
        <app-carousel v-else :slides="slides" autoplay/>
      </div>
    </section>

      <section class="presentation-section">
          <div class="img-container">
            <img src="@/assets/img/illustrations/approche.png" alt="Approche E-Aligner" loading="lazy" defer="async" />
          </div>
          <div class="content-container">
            <h2>
              <app-subtitle>Présentation de E-Aligner</app-subtitle>
              Une approche thérapeutique plus efficace
            </h2>
            <p>
              Cliniquement prouvé, le système E-Aligner est conçu pour apporter au praticien une solution simple, efficace et personnalisée à ses patients.
              <br><br>
              Confortable, amovible et invisible, la gouttière E-Aligner est l’appareil orthodontique idéal, qui déplace les dents progressivement avec efﬁcacité et douceur.
            </p>
          </div>
      </section>

      <section class="strengths-section">
        <h2>
          <app-subtitle>Nos points forts</app-subtitle>
          Pourquoi nous choisir ?
        </h2>
        <ul class="strengths-content">
          <li>
            <img src="@/assets/img/illustrations/savoir-faire.png" alt="Savoir-faire" loading="lazy"/>
            <p>Notre savoir faire</p>
          </li>
          <li>
            <img src="@/assets/img/illustrations/humain.png" alt="Humain" loading="lazy"/>
            <p>L'humain</p>
          </li>
          <li>
            <img src="@/assets/img/illustrations/sur-mesure.png" alt="Sur-mesure" loading="lazy"/>
            <p>Le sur-mesure</p>
          </li>
          <li>
            <img src="@/assets/img/illustrations/reactivite.png" alt="Reactivité" loading="lazy"/>
            <p>L'écoute et la réactivité</p>
          </li>
        </ul>
      </section>

      <video-block
        videoSrc="https://www.youtube-nocookie.com/embed/AH0WZxmChr8"
        title="Qui sommes-nous ?"
        subtitle="L'équipe"
      ></video-block>

      <steps-block
        id="comment-ca-marche"
        title="Demandez votre traitement E-aligner"
        :steps="steps"
      />

      <section class="results-section">
        <h2>
          <app-subtitle>Nos résultats</app-subtitle>
          Un traitement qui vise un alignement esthétique
        </h2>
        <div class="image-container">
          <div>
            <img src="@/assets/img/illustrations/avant1.png" alt="Avant" loading="lazy"/>
          </div>
          <div>
            <img src="@/assets/img/illustrations/apres1.png" alt="Apres" loading="lazy"/>
          </div>
          <div>
            <img src="@/assets/img/illustrations/avant2.png" alt="Avant" loading="lazy"/>
          </div>
          <div>
            <img src="@/assets/img/illustrations/apres2.png" alt="Apres" loading="lazy"/>
          </div>
          <div>
            <img src="@/assets/img/illustrations/avant3.png" alt="Avant" loading="lazy"/>
          </div>
          <div>
            <img src="@/assets/img/illustrations/apres3.png" alt="Apres" loading="lazy"/>
          </div>
        </div>
      </section>

      <section class="contact-us">
        <div>
          <div class="contact-us-content">
            <div class="title-container">
              <p>Interessé par E-aligner</p>
              <h2>Comment puis-je bénéficier de ce soin ?</h2>
            </div>
            <p>Contactez votre praticien ! C’est votre professionnel de santé qui sera en mesure de vous renseigner. </p>
            <div class="cta-container">
              <a href="/pourquoi-nous-choisir#etapes">
                <app-button look="primary" background="dark">Vous êtes professionel ?</app-button>
              </a>
              <a href="/foire-aux-questions">
                <app-button look="secondary">Vous avez d'autres questions ?</app-button>
              </a>
            </div>
          </div>
          <div class="contact-us-img">
            <img src="@/assets/img/illustrations/ealigner-panorama-img.png" alt="Vous avez des questions ? Contactez-nous !" loading="lazy" defer="async" />
          </div>
        </div>
      </section>

      <reviews-block title="Ils nous font confiance" :reviews="reviews" />
  </div>
</template>

<script>
import VideoBlock from '@/components/VideoBlock.vue';
import StepsBlock from '@/components/StepsBlock.vue';
import ReviewsBlock from '@/components/ReviewsBlock.vue';

import SvgPhone from '@/assets/img/icons/phone.svg';
import SvgJaw from '@/assets/img/icons/jaw.svg';
import SvgTeeth from '@/assets/img/icons/teeth.svg';
import SvgPatientFile from '@/assets/img/icons/patient-file.svg';

import slideApi from '@/services/api/slide';

export default {
  name: 'WhoWeAre',
  components: {
    VideoBlock,
    StepsBlock,
    ReviewsBlock,
  },
  data() {
    return {
      isLoading: false,
      slides: [],
      steps: [
        {
          icon: SvgPhone,
          title: 'Prenez rendez-vous avec votre praticien',
          description: `Consultez votre praticien !
Lors d’un premier rendez-vous, votre praticien évalue la possibilité d’un traitement de correction dentaire par gouttières d’alignement E-Aligner. Il pourra ainsi éventuellement prendre les examens complémentaires (photos, radios, empreintes) pour une étude personnalisée.`,
        },
        {
          icon: SvgJaw,
          title: 'Votre nouveau sourire en 3D',
          description: 'Grâce à la technologie novatrice E-Aligner, vous pouvez en compagnie de votre praticien visualiser les différentes étapes successives de votre traitement jusqu’au résultat final.',
        }, {
          icon: SvgTeeth,
          title: 'La gouttière E-Aligner transparente',
          description: 'Vos gouttières E-Aligner sont fabriquées sur mesure et s’ajustent parfaitement à votre ligne gingivale vous apportant un confort durant les 22 heures de port par jour recommandées. Chaque gouttière portée correspond à une étape de correction progressive de votre dentition. Chaque gouttière est remplacée en moyenne toutes les 2 à 3 semaines suivant la prescription de votre praticien. Tout au long de votre traitement, votre praticien est à vos côtés.',
        }, {
          icon: SvgPatientFile,
          title: 'Un traitement tout confort fait pour vous',
          description: 'Les gouttières E-Aligner amovibles et quasiment invisibles, vous permettent de vivre normalement, tout en suivant un traitement orthodontique. Transparentes, les gouttières E-Aligner s\'ajustent parfaitement à votre dentition. Votre entourage remarquera davantage votre nouveau sourire que la gouttière elle-même. Amovibles, les gouttières E-Aligner se retirent facilement afin de vous permettre de manger sans contrainte, de vous brosser les dents, de conserver une hygiène buccale saine ou de pratiquer votre activité sportive en toute sérénité.',
        },
      ],
      reviews: [
        {
          title: 'Le suivi du traitement est plus simple',
          author: 'Rodolphe',
          text: 'Le choix de cette solution s’est fait par un besoin de compléter mes traitements en matière d’orthodontie. C’est une production locale et donc des échanges avec le laboratoire plus simples et rapides. Je propose cette solution à des patients adultes présentant des encombrements faibles à modérés. Souvent cela correspond à des récidives d’un traitement multi-attaches. Je l’utilise également pour des finitions de traitement ou de faibles encombrements chez l’adolescent. Le traitement est complètement invisible pour l’entourage et permet une hygiène normale. Le suivi du traitement est plus simple avec des séances de contrôle peu chronophages. La solution permet également le traitement des cas plus complexes où un traitement multi-attaches collées des secteurs latéraux et postérieurs aura été fait au préalable.',
        },
        {
          title: 'On a toujours un interlocuteur pour nous répondre',
          author: 'Véronique',
          text: 'J’utilise cette solution d’alignement parce qu’elle est française et que l’on a toujours un interlocuteur pour répondre aux questions. J’emploie cette technique pour des cas simples de classe 1 ou classe 2 division 1, car l’alignement ne se fait pour l’instant que de prémolaire à prémolaire, et dans certains cas de récidives également. Elle offre tous les avantages des aligners : le confort pour le patient, l’esthétique, pas d’urgence, moins de stress, même s’il ne faut pas croire qu’il est toujours facile de pratiquer cette technique. Les patients ont du mal à imaginer le résultat final et il est vrai qu’avec le système de visualisation post-traitement c’est tout à fait encourageant et motivant. L’idéal serait que la solution permette de réaliser un full traitement de molaire à molaire.',
        },
        {
          title: 'C’est 100 % français',
          author: 'Sébastien',
          text: 'J’ai choisi cette solution parce qu’elle est 100 % française : si nous voulons conserver un savoir- faire sur notre territoire, il faut le promouvoir. C’est également un choix de proximité. J’utilise cette solution pour diverses phases et cas en orthodontie, chez mes patients adultes qui désirent de l’invisible mais aussi pour mes « cas ados » avec petits encombrements ainsi que pour mes contentions maxillaires post- traitements. Les avantages sont ceux d’un traitement par gouttières : discrétion et confort pour le patient, maîtrise et connaissance du résultat pour le praticien. L’intérêt principal de la visualisation réside dans la motivation du patient. Il peut ainsi se projeter vers le résultat esthétique final. Communication et visualisation, c’est le duo gagnant pour motiver son patient à s’engager vers le traitement qu’on lui propose.',
        },
        {
          title: 'La simplicité du protocole',
          author: 'Abdoul',
          text: 'Cette solution d’alignement m’a été présentée lors du congrès de l’ADF il y a quelques années. Ce qui m’a séduit c’est la simplicité du protocole et la rapidité du service. Il y a aussi le fait que les gouttières soient de fabrication française, cela rassure beaucoup les patients quant à la qualité du travail. J’utilise cette solution dans les cas d’encombrements, diastèmes, versions et égressions dentaires pour le secteur antérieur principalement et dans certains cas jusqu’aux prémolaires. Il suffit de prendre des empreintes, des clichés et envoyer le tout. Le laboratoire propose un traitement qu’il suffit de valider avec le patient pour lancer la réalisation des gouttières. Le patient peut visualiser l’avancée du traitement grâce aux vidéos et comparer « l’avant-après ».',
        },
      ],
    };
  },

  methods: {
    // API: récupère la liste des slides
    async getSlides() {
      this.isLoading = true;
      try {
        const response = await slideApi.getSlideList();
        this.slides = response.data;
      } catch (error) {
        // Ne pas indiquer qu'il y a un souci
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.getSlides();
  },
};
</script>

<style lang="sass">
.who-we-are
  .header-section
    display: flex
    flex-direction: column
    background: $bg-light-color
    padding: 48px 0
    height: max-content

    .text-container
      margin: auto
      max-width: 900px
      h1
        text-align: center
        margin: 0
        strong
          @include h1
          color: $color-primary-100

      &::before
        @include title-background

    .slide-container
      width: 90%
      margin: 48px auto 50px auto
      max-width: 1200px
      .app-spinner
        margin: auto

    .img-container
      display: flex
      position: relative
      z-index: 1

      img:first-child
        width: 100%
        border-radius: 0 40px 0 40px
      img:last-child
        width: 25%
        position: absolute
        top: 0
        left: 0
        transform: translate(-40%, -40%)

      &::after
        position: absolute
        z-index: -1
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 136 144' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.7' d='M2.74701 103.737L101.821 2.36907L134.588 141.913L2.74701 103.737Z' stroke='%236BBB32' stroke-width='2'/%3E%3C/svg%3E")
        bottom: 0
        right: 0
        transform: translate(100%, 100%)

  .presentation-section
    margin: 50px auto
    width: 90%
    max-width: 1200px
    background: $bg-light-color
    display: flex
    border-radius: 40px 0 40px 0
    gap: 5vw
    padding: 70px 100px
    align-items: center
    .img-container
      position: relative
      z-index: 0
      img
        width: 390px
        height: 390px
        object-fit: cover
        border-radius: 40px 0 40px 0
        z-index: 2
        position: relative
      &::before
        width: 130px
        display: block
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 152 198' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.142578 126.749L38.267 0.194824L128.804 96.4888L0.142578 126.749Z' fill='%236BBB32'/%3E%3Cpath d='M66.1804 173.943L129.655 111.676L151.843 197.78L66.1804 173.943Z' fill='%236BBB32'/%3E%3C/svg%3E")
        position: absolute
        z-index: 1
        bottom: 45%
        left: 5%
        transform: translate(-50%, 50%)
    .content-container
      width: 60%
      .app-subtitle
        text-align: left

  .strengths-section
    margin: 150px auto 100px auto

    h2
      margin: 0 auto 90px auto
      text-align: center

    ul
      display: flex
      justify-content: center
      align-items: center
      gap: 70px
      list-style: none
      li
        text-align: center
        img
          width: 120px
        p
          @include text-bold
          color: $color-primary-180

  .results-section
    margin: 100px auto

    h2
      text-align: center
      margin-bottom: 40px

    .image-container
      display: grid
      grid-template-columns: 1fr 1fr
      gap: 50px 30px
      align-items: center
      div
        position: relative
        img
          border-radius: 32px 0
          width: 345px
          height: 260px
          object-fit: cover
        &::before
          position: absolute
          content: ""
          background-image: url('~@/assets/img/logo.png')
          z-index: 3
          width: 125px
          height: 21px
          top: 14px
          left: 14px
          background-size: contain
          background-repeat: no-repeat
        &::after
          @include h3
          position: absolute
          bottom: 12px
          right: 20px
          display: block
          z-index: 3

        &:nth-child(even)
          justify-self: start
          &::after
            content: 'Après'
        &:nth-child(odd)
          justify-self: end
          &::after
            content: 'Avant'

  .contact-us
    background-color: $color-primary-100
    display: flex
    justify-content: center
    padding: 7rem 3rem

    > div
      display: flex
      flex-direction: row
      justify-content: space-between
      max-width: 1200px
      column-gap: 2rem
      width: 100%

    &-content
      display: flex
      flex-direction: column
      justify-content: center
      color: $white
      gap: 1.5rem

      .title-container
        p
          @include subtitle
          color: $white
          margin: 0
          text-transform: uppercase

        h2
          color: $white
          margin: 0
      > p, .title-container
        max-width: 450px
      .cta-container
        display: flex
        flex-wrap: wrap
        gap: 23px

    &-img
      display: flex
      width: 100%
      max-width: 500px

      > img
        object-fit: contain

  @media (max-width: 768px)
    section.header-section
      flex-direction: column
      margin: 0
      padding: 0
      width: 100%
      .text-container
        margin-top: 5vw
        padding: 10vw 5vw 0 5vw
        gap: 5vw
      .slide-container
        width: 100%
        margin-bottom: -5px
      .app-carousel
        width: 100%

    section.presentation-section
      flex-direction: column
      padding: 2rem
      padding-bottom: 6rem
      width: 100%
      .img-container
        img
          width: 100%
          height: auto
      .content-container
        width: auto

    section.strengths-section
      .strengths-content
        flex-direction: column
        gap: 48px
        padding: 0
    section.results-section
      .image-container
        gap: 4vw
        >div
          img
            width: 42vw
            height: 34vw
            border-radius: 10% 0
          &::before
            width: 40%
          &::after
            font-size: 18px

    section.contact-us
      padding: 3rem 1.5rem 5rem 1.5rem

      > div
        flex-direction: column-reverse

      &-content
        max-width: 100%

        h2
          font-size: 22px

      &-img
        max-width: 100%
        margin-bottom: 4rem

</style>
