<template>
  <section class="smile-photo-aligner">
    <div class="hero">
      <div>
        <h1>Transformez votre <strong>sourire</strong> avec E-Aligner</h1>
        <p>
          Vous rêvez d'un sourire parfait ? Nos gouttières d'alignement sont là pour ça ! Avec notre outil interactif, découvrez comment votre sourire peut être transformé. Comparez le avant/après et imaginez-vous avec vos dents alignées.
        </p>
        <div class="actions">
          <app-button look="primary" background="dark" @click="scrollToTransform">Cliquez pour tester sur votre photo ↓</app-button>
        </div>
      </div>
      <div>
        <img src="@/assets/img/smile/hero.jpg" alt="Une femme sourit à pleine dents">
      </div>
    </div>
    <div class="transform">
      <div>
        <div class="info">AVANT / APRÈS</div>
        <h2>
          Transformez votre sourire : importez votre photo
        </h2>
        <ol>
          <li><strong>Importez votre photo :</strong> Cliquez sur le bouton ci-dessous pour importer une photo de votre sourire. Assurez-vous que la photo montre bien vos dents.</li>
          <li><strong>Voir la magie opérer :</strong> Notre outil de traitement d'image va analyser votre photo et vous montrer une simulation du résultat après le traitement. Rapide et réaliste !</li>
          <li><strong>Comparez le avant / après :</strong> Regardez la transformation de votre sourire avec une comparaison côte à côte et imaginez-vous avec ce sourire parfait.</li>
        </ol>
        <div class="content">
          <div>
            <div class="card">
              <span class="label">AVANT</span>
              <img v-if="beforeImage" :src="beforeImage" alt="Visage">
              <img v-else src="@/assets/img/smile/before.jpg" alt="Femme souriante avec les dents non-alignées">
              <app-upload v-if="uploadVisible" v-model="file" :progress="progressUpload"/>
            </div>
            <app-button look="primary" background="dark" @click="upload">Importez votre photo</app-button>
            <small><em>au moins 1200px x 1200px, <br/>photo de votre visage de face, dents visibles, machoire fermée</em></small>
          </div>
          <div>
            <div class="card">
              <span class="label">APRÈS</span>
              <app-spinner v-if="beforeImage && !afterImage" />
              <small class="info" v-if="beforeImage && !afterImage">
                La génération de l'image peut prendre jusqu'à 5 minutes.
              </small>
              <img class="dark" v-if="beforeImage && !afterImage" :src="beforeImage" alt="Visage">
              <img v-else-if="afterImage" :src="afterImage" alt="Visage">
              <img v-else src="@/assets/img/smile/after.jpg" alt="Femme souriante avec les dents alignés après son traitement">
            </div>
            <p>Votre sourire, après !</p>
            <small>Voici à quoi pourrait ressembler votre sourire après un traitement.</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mentions">
      <p>
        Pour un meilleur résultat, utilisez une photo avec une bonne luminosité où vos dents sont bien visibles. Attention, les résultats de la simulation effectuée par l'intelligence artificielle ne sont pas contractuels et ne garantissent pas les résultats finaux du traitement.
      </p>
      <p>
        <small>Photo en header de <a href="https://unsplash.com/fr/@wilks_and_cookies?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Connor Wilkins</a> sur Unsplash.</small>
      </p>
    </div>
  </section>
</template>

<script>
import document from '@/services/api/document';
import uploadService from '@/services/utils/upload';

export default {
  name: 'demo',
  components: {
  },
  data() {
    return {
      file: null,
      progressUpload: 0,
      uploadVisible: false,
      beforeImage: null,
      afterImage: null,
      predictionId: null,
    };
  },
  watch: {
    file: 'handleSimpleFile',
  },
  methods: {
    scrollToTransform() {
      const targetElement = window.document.querySelector('.transform .content');
      targetElement.scrollIntoView({ behavior: 'smooth' });
    },
    upload() {
      this.uploadVisible = true;

      this.$message.show({
        title: 'Indications pour la photo',
        confirmText: 'J’ai compris !',
        hasCancel: false,
        text:
          'La photo doit être une photo de face de votre visage, dents visibles et machoire fermée.',
        onConfirm: () => {
          setTimeout(() => {
            window.document.querySelector('input').click();
          }, 200);
        },
      });
    },
    async checkImgUrl() {
      const maxAttempts = 100;
      const delay = 5000;
      let attempt = 0;

      while (attempt < maxAttempts) {
        // Fetch the prediction details
        // eslint-disable-next-line no-await-in-loop
        const response = await document.get(this.predictionId);
        const imgUrl = response.data?.img_url;

        if (imgUrl) {
          this.afterImage = imgUrl;
          break;
        }

        // eslint-disable-next-line no-plusplus
        attempt++;
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    },
    async handleSimpleFile() {
      if (this.file) {
        try {
          this.beforeImage = URL.createObjectURL(this.file);
          this.afterImage = null;
          this.uploadVisible = false;
          if (window.matchMedia('(max-width: 800px)').matches) {
            window.scrollBy({
              top: 520,
              behavior: 'smooth',
            });
          }

          const data = await document.upload(this.file, (progressEvent) => {
            // On calcule le pourcentage de la progression
            this.progressUpload = uploadService.getProgress(progressEvent);
          });
          this.predictionId = data.data.predictionId;

          this.$notification.show({
            text: `Fichier ${this.file.name} uploadé !`,
          });

          await this.checkImgUrl();

          this.progressUpload = 0;
          this.file = null;
          this.uploadVisible = false;
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu une erreur lors de l’envoi de votre fichier.',
            confirmText: 'OK',
            hasCancel: false,
          });

          throw error;
        }
      }
    },
  },
};
</script>

<style lang="sass">
.smile-photo-aligner
  .hero
    display: flex
    gap: 40px
    min-height: 80vh
    align-items: center
    margin: 50px auto
    max-width: 1280px
    padding-top: 80px
    background-image: url('~@/assets/img/smile/ellipse.png')
    background-size: contain
    background-repeat: no-repeat
    @media screen and (max-width: 800px)
      flex-direction: column-reverse
      gap: 10px
      > div
        padding: 0 20px
    > div
      max-width: 680px
      img
        transform: translateY(-40px)
        @media screen and (max-width: 800px)
          max-width: 300px
    h1
      font-size: 58px
      color: #559528
      strong
        font-size: 100%
        font-weight: 700
        font-family: 'Montserrat', Helvetica, Arial, sans-serif
        color: #6BBB32
      @media screen and (max-width: 800px)
        margin: 0
    p
      font-size: 20px
      font-family: 'DM Sans', Helvetica, Arial, sans-serif
      color: #4D5351
    .actions
      padding: 50px 0
      text-align: center
      .app-button
        text-transform: uppercase
        font-size: 24px
  .transform
    padding: 50px 0
    background: linear-gradient(45deg, #FFF4E3, #F4FFEC, #FFFBDC)
    @media screen and (max-width: 800px)
      padding: 20px
    > div
      margin: 50px auto
      max-width: 1280px
    .info
      font-size: 11px
      font-weight: 600
      color: #6BBB32
    .content
      display: flex
      gap: 10px
      padding: 30px 0
      @media screen and (max-width: 800px)
        flex-direction: column
        gap: 30px
        .arrow
          margin: 30px 0
          transform: rotate(90deg)
      > div
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-start
        gap: 20px
      p
        margin: 10px 0
        font-size: 24px
        font-weight: 700
        color: #6BBB32
      .card
        position: relative
        padding: 10px
        background: #242424
        border: 5px solid #7A7768
        border-radius: 10px
        .info
          position: absolute
          top: calc(50% + 80px)
          left: 0
          right: 0
          padding: 0 100px
          text-align: center
          color: white
          font-size: 1rem
          z-index: 10
        .app-upload
          position: absolute
          top: calc(50% - 30px)
          left: calc(50% - 150px)
          width: 300px
          font-size: 12px
          label
            padding: 30px
        .app-spinner
          position: absolute
          top: calc(50% - 40px)
          left: calc(50% - 40px)
          z-index: 10
        img
          border-radius: 10px
          &.dark
            filter: brightness(0.35) blur(5px)
        .label
          position: absolute
          top: 20px
          left: 20px
          padding: 5px 10px
          color: white
          border: 1px solid white
          background: rgba(0,0,0,0.4)
      small
        text-align: center
    h2
      font-size: 40px
      color: #272727
    ol
      padding: 0 0 0 20px
      font-size: 15px
      li
        margin: 12px 0
        color: #6f6e68
        &::marker
          font-weight: 700
        strong
          font-size: 100%
          font-weight: 700
          font-family: 'Montserrat', Helvetica, Arial, sans-serif
  .mentions
    margin: 50px auto
    padding: 40px 0
    max-width: 1280px
    font-size: 16px
    font-family: 'DM Sans', Helvetica, Arial, sans-serif
    color: #4D5351
    @media screen and (max-width: 800px)
      padding: 20px
</style>
