<template>
  <footer class="footer-nav">
    <div class="footer-content">
      <nav>
        <div>
          <ul>
            <li class="logo">
              <img v-if="!isDpiScreen" src="@/assets/img/logo-white.png" alt="Logo Ealigner" loading="lazy" decoding="async" />
              <img v-else src="@/assets/img/logo-dpi-white.png" alt="Logo Ealigner et DPI" loading="lazy" decoding="async" />
            </li>
            <li>
              <p>
                <strong>
                  <router-link :to="{ name: 'who-we-are' }" class="footer-link">Qui sommes-nous ?</router-link>
                </strong>
              </p>
            </li>
            <li>
              <p>
                <router-link :to="{ name: 'team' }" class="footer-link">Notre équipe</router-link>
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h5 class="footer-title">Partenaires</h5>
          <ul>
            <li>
              <p>
                <router-link :to="{ name: 'partner' }" class="footer-link">Notre partenaire DPI</router-link>
              </p>
            </li>
            <li>
              <p>
                <router-link :to="{ name: 'why-choose-us' }" class="footer-link">Pourquoi nous choisir ?</router-link>
              </p>
            </li>
            <li>
              <a :href="practitionerApp" target="_blank">
                <app-button look="primary">Inscription praticien</app-button>
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h5 class="footer-title">Assistance</h5>
          <ul>
            <li>
              <p>
                <router-link :to="{ name: 'faq' }" class="footer-link">FAQ</router-link>
              </p>
            </li>
            <li>
              <p>
                <a href="/assets/documents/CGV_E-Aligner_2022.pdf" target="_blank" class="footer-link">Conditions générales</a>
              </p>
            </li>
            <li>
              <p>
                <a href="/assets/documents/EAL_SITE_Mentions_legales.pdf" target="_blank" class="footer-link">Mentions légales</a>
              </p>
            </li>
            <li>
              <p>
                <a href="/assets/documents/EAL_SITE_Politique_de_confidentialite.pdf" target="_blank" class="footer-link">Politique de confidentialité</a>
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h5 class="footer-title">Contact</h5>
          <ul>
            <li>
              <p>
                <router-link :to="{ name: 'contact' }" class="footer-link">Contactez-nous</router-link>
              </p>
            </li>
            <li>
              <p class="footer-address">
                <strong>
                  La Filature Numérique<br />
                  24 Bd Pierre Lecoq,<br />
                  49300 Cholet
                </strong>
              </p>
            </li>
            <li>
              <p>
                <a class="footer-mail" href="mailto:contact@ealigner.com">
                  <strong>contact@ealigner.com</strong>
                </a>
              </p>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="footer-end">
      <div>
        <p>Copyright © 2022 E-Aligner</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footer-nav',
  props: {
    isDpiScreen: Boolean,
  },
  computed: {
    practitionerApp() {
      return process.env.VUE_APP_FRONT_URL;
    },
  },
};
</script>

<style lang="sass">
.footer-nav
  display: flex
  flex-direction: column
  width: 100%
  background-color: var(--background-color, $color-primary-190)

  .footer-address
    color: var(--main-color, $color-primary-100)

  .footer-content
    width: 90%
    max-width: 1150px
    margin: 5rem auto 8rem auto
    display: flex

    nav
      width: 100%
      display: grid
      grid-template-columns: repeat(4, 1fr)
      grid-template-rows: 1fr
      grid-gap: 5rem

      .logo
        display: flex

      img
        object-fit: contain
        width: 168px

      > div
        display: flex
        flex-direction: column

        h5
          margin-bottom: 2rem

        ul
          padding: 0
          margin: 0
          list-style: none

        li
          display: flex
          flex-direction: column
          align-items: flex-start

          &:not(:last-child)
            margin-bottom: 1rem

          p
            margin: 0

  .footer-title
    @include subtitle
    margin: 0
    color: var(--main-color, $main-color)
    text-transform: uppercase

  .footer-mail
    color: var(--main-color, $main-color)

  .footer-link
    @include text
    color: $white

    &:hover
      text-decoration: underline

  .footer-end
    width: 100%
    display: flex
    position: relative
    justify-content: center

    &::before
      content: ""
      background-color: var(--main-color, $main-color)
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 1px

    p
      @include text-infos
      color: $color-gray-10

  @media (max-width: 1024px)
    .footer-content
      margin: 2rem auto 4rem auto
      width: 75%

      nav
        display: flex
        flex-direction: column
        grid-gap: 4rem

        .logo
          align-items: center
          margin-bottom: 2rem !important

    .footer-end p
      text-align: center
</style>
