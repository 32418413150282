<template>
  <section class="reviews-block">
    <!-- Titre du block -->
    <h2>
      <app-subtitle>Les avis des praticiens</app-subtitle>
      {{ title }}
    </h2>
    <!-- Contenu du block -->
    <div class="reviews-block-content">
      <div class="reviews-carousel" @touchstart="onTouchStart" @touchend="onTouchEnd">
        <div class="reviews-container" :style="{ width: `calc((${reviewWidth}px) * ${reviews.length})`, transform: `translateX(calc(-${reviewWidth}px * ${currentSlide}))`}">
          <div class="review" v-for="(review, index) in reviews" :key="index" :class="{ unrolled:  isFullTextDisplayed && currentSlide === index }">
            <div class="review-header">
              <review-icon/>
              <p class="review-title">"{{review.title}}"</p>
              <p class="review-author">{{review.author}}</p>
            </div>
            <div class="review-body">
              <p class="review-text">
                {{review.text}}
              </p>
              <a class="read-more-button" @click="isFullTextDisplayed = !isFullTextDisplayed" >{{isFullTextDisplayed && currentSlide === index ? 'Réduire' : 'Lire la suite'}}</a>
            </div>
          </div>
        </div>
        <div class="reviews-carousel-control-right" @click="goToSlide(currentSlide + 1)"></div>
        <div class="reviews-carousel-control-left" @click="goToSlide(currentSlide - 1)"></div>
      </div>
      <div class="reviews-carousel-controls">
        <div class="review-dot" v-for="(review, index) in reviews" :key="index" :class="{ active: currentSlide === index }" @click="goToSlide(index)">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import reviewIcon from '@/assets/img/icons/review.svg?inline';

export default {
  name: 'reviews-block',
  components: {
    reviewIcon,
  },
  props: {
    // Format [{ title, author, text }] attendu
    reviews: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    reviewWidth() {
      // 800px on desktop, 300px on mobile
      return window.innerWidth > 768 ? 800 : window.innerWidth * 0.8;
    },
  },
  data() {
    return {
      currentSlide: 0,
      isFullTextDisplayed: false,
      touchStartX: 0,
    };
  },
  methods: {
    goToSlide(slide) {
      if (slide < 0 || slide > this.reviews.length - 1) {
        return;
      }
      this.currentSlide = slide;
      this.isFullTextDisplayed = false;
    },
    onTouchStart(event) {
      this.touchStartX = event.changedTouches[0].clientX;
    },
    onTouchEnd(event) {
      const touchEndX = event.changedTouches[0].clientX;
      const touchDiff = touchEndX - this.touchStartX;
      if (touchDiff > 50) {
        this.goToSlide(this.currentSlide - 1);
      } else if (touchDiff < -50) {
        this.goToSlide(this.currentSlide + 1);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.reviews-block
  text-align: center
  background: $bg-light-color
  padding: 100px 120px
  > h2
    margin: 0 0 70px 0
  .reviews-block-content
    .reviews-carousel
      width: 100%
      overflow: hidden
      padding-left: calc(50vw - 120px - 400px - 0.5em)
      margin-bottom: 27px
      position: relative
      .reviews-container
        display: flex
        justify-content: space-around
        transition: transform 0.3s ease-in-out
        .review
          width: 800px
          max-height: 367px
          background: $white
          padding: 2em 3em
          margin: 0 1.5em
          border-radius: 20px 0
          transition: all 0.5s ease-in-out
          .review-header
            .review-title
              font-weight: bold
              color: $main-color
            .review-author
              font-weight: bold
          .review-body
            .review-text
              max-height: 125px
              overflow: hidden
              text-overflow: ellipsis
              transition: all 0.5s ease-in-out
            .read-more-button
              text-decoration: underline
              cursor: pointer
          &.unrolled
            max-height: 500px
            .review-body
              .review-text
                max-height: 500px
      .reviews-carousel-control-right
        position: absolute
        height: 100%
        width: calc(50vw - 120px - 400px - 1.5em)
        z-index: 1
        top: 0
        right: 0
        cursor: pointer
      .reviews-carousel-control-left
        position: absolute
        height: 100%
        width: calc(50vw - 120px - 400px - 1.5em)
        z-index: 1
        top: 0
        left: 0
        cursor: pointer
    .reviews-carousel-controls
      display: flex
      justify-content: center
      .review-dot
        width: 1em
        height: 1em
        border-radius: 100px
        margin: 0 11px
        background: $white
        transition: all 0.3s ease-in-out
        cursor: pointer
        &.active
          background: $main-color
          width: 48px
@media (max-width: 768px)
  .reviews-block
    padding: 70px 0
    .reviews-block-content
      .reviews-carousel
        padding-left: calc(50vw - 35vw - 5vw)
        .reviews-container
          .review
            width: 70vw
            max-height: 620px
            margin: 0 2.5vw
            transition: all 1s ease-in-out
            padding: 31px 24px
            .review-body
              .review-text
                font-size: 14px
                max-height: 220px
                transition: all 1s ease-in-out
            &.unrolled
              max-height: 1000px
              .review-body
                .review-text
                  max-height: 1000px
        .reviews-carousel-control-right
          width: calc(50vw - 35vw - 5vw)
        .reviews-carousel-control-left
          width: calc(50vw - 35vw - 5vw)
</style>
